import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Translated} from '../../shared/classes/translated.class';
import {TranslateService} from '@ngx-translate/core';
import {FormGroup, FormBuilder} from '@angular/forms';
import {RouterHelper} from '../../shared/helpers/router.helper';
import {SessionService} from '../../shared/services/session.service';
import {TestsetService} from '../../shared/services/testset.service';
import {TestProvider } from '../../shared/models/test.model';
import {LampUpdateResponse} from '../../shared/models/common';
import {Subscription} from 'rxjs';
import { LocalStorageHelper } from '../../shared/helpers/localhost.helper';

@Component({
  selector: 'app-testprovider-edit',
  templateUrl: './testprovider-edit.component.html',
  styleUrls: ['./testprovider-edit.component.less']
})

export class TestproviderEditComponent extends Translated implements OnInit, OnDestroy {
  public testproviderForm: FormGroup;
  private _providerData: TestProvider = { id: 0, description: undefined, typename: undefined, version: undefined}; 

  constructor(protected translateService: TranslateService,
    protected localStorageHelper: LocalStorageHelper,
    private route: ActivatedRoute,
    private testService: TestsetService,
    private formBuilder: FormBuilder,
    protected sessionService: SessionService) {
super(translateService, localStorageHelper, sessionService);
}  
  
  ngOnInit(): void {
    this.initFromSession();
  }
  ngOnDestroy(): void {
  }

  initThisSubtypeFromSession(): void {
    if (TestproviderEditComponent.isCreateUrl(this.route)) {
      this.createForm();
      return;
    }
    let thisOne = this;
    let urlSubscr: Subscription = this.route.params.subscribe(
      params => {
        let id: number = params['id'];
        if (id) {
          thisOne.testService.getTestprovider(id).then((provider: TestProvider) => {
            this._providerData = provider;
            this.createForm();
          });
        }
        urlSubscr.unsubscribe();
      });
  }

  public isNew(): boolean {
      return !(this._providerData.id > 0);
  }

  private createForm(): void {
    this.testproviderForm = this.formBuilder.group({
      id: [this._providerData.id],
      description: [this._providerData.description],
      typename: [this._providerData.typename],
      infoUrl: [this._providerData.infoUrl],
      version: [this._providerData.version],
      param1: [this._providerData.param1],
      param2: [this._providerData.param2],
      param3: [this._providerData.param3], 
      param1value: [this._providerData.param1value],
      param2value: [this._providerData.param2value],
      param3value: [this._providerData.param3value]
    });
  }

  public save(): Promise<void> {
    if (this.isNew()) {
        return this.testService.createTestprovider(this._providerData)
          .then((successfulResponseMessage: LampUpdateResponse) => {
            this.handleServerResponse(successfulResponseMessage);
          });
      } else {
        return this.testService.updateTestprovider(this._providerData)
          .then((successfulResponseMessage: LampUpdateResponse) => {
            this.handleServerResponse(successfulResponseMessage);
          });
      }
  }

}