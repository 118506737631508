import { Component, OnInit, OnDestroy } from '@angular/core';
import { TestsetService } from '../shared/services/testset.service';
import { SessionService } from '../shared/services/session.service';
import { LocalStorageHelper } from '../shared/helpers/localhost.helper';
import { Translated } from '../shared/classes/translated.class';
import { TranslateService } from '@ngx-translate/core';
import { CorporaFilterSettings, CorporaHierarchy, TestFragment } from '../shared/models/test.model';
import { LampUpdateResponse, FilterSetting } from '../shared/models/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';

interface State {
  corpora: CorporaHierarchy[];
  refineFilter: CorporaFilterSettings[];
  searchArgument: string;
  searchArgumentType: string;
}

@Component({
  selector: 'app-corpora-list',
  templateUrl: './corpora-list.component.html',
  styleUrls: ['./corpora-list.component.less']
})

export class CorporaListComponent extends Translated implements OnInit {
  public corpora: CorporaHierarchy[] = [];
  public corporaFiltering: CorporaHierarchy[] = [];
  public totalTestFragments: number;
  public selectedCorporaId: number = undefined;
  private _lastSearchArgument: string;
  private _lastSearchArgumentType: string;
  public isNoFullRefresh: boolean = true;
  public showingElementId: number;
  public expandedElementId: number;
  public idIndexKey = "last-visit-fragment-id";


  public filterFields: CorporaFilterSettings[] = [
    {
      name: 'LABEL_1',
      value: '',
      label: 'TEST.label-1'
    },
    {
      name: 'LABEL_2',
      value: '',
      label: 'TEST.label-2'
    },
    {
      name: 'LABEL_3',
      value: '',
      label: 'TEST.label-3'
    },
    {
      name: 'LABEL_4',
      value: '',
      label: 'TEST.label-4'
    },
    {
      name: 'LABEL_5',
      value: '',
      label: 'TEST.label-5'
    },
    {
      name: 'DISCUSSION',
      value: '',
      label: 'TEST.discussion'
    },
  ];
  public filterBehaviorSubject: BehaviorSubject<CorporaFilterSettings[]> = new BehaviorSubject<CorporaFilterSettings[]>(this.filterFields);
  public filterObservable: Observable<CorporaFilterSettings[]> = this.filterBehaviorSubject.asObservable();


  ngOnInit(): void {
    this.initFromSession();
    this.isNoFullRefresh = this.localStorageHelper.getTabSetting('corpora_no_refresh') === 'false' ? false : true;
    this.getVisitElement();
  }

  protected filterSettingName(): string {
    return 'corpora_filter';
  }

  protected getFilteredData(searchArgument: string, searchArgumentType: string): Promise<void> {
    if(!searchArgument || !searchArgumentType)
      return;
    
    this._lastSearchArgument = searchArgument;
    this._lastSearchArgumentType = searchArgumentType;
    this.loadLocalResultIfExist();
    if (this.corpora && this.corpora.length > 0) {
      this.totalTestFragments = this.corpora.map(corpora => corpora.fragments.length).reduce((a, b) => a + b, 0);
      this.refineFilter();
      return Promise.resolve();
    }

    return this._service.getTestfragmentList(searchArgument, searchArgumentType)
      .then((corporaH: CorporaHierarchy[]) => {
        this.corpora = corporaH;
        this.totalTestFragments = corporaH.map(corpora => corpora.fragments.length).reduce((a, b) => a + b, 0);
        this.refineFilter();
      });
  }

  initThisSubtypeFromSession(): void {
  }

  public refineFilter(): void {
    const corporaList: CorporaHierarchy[] = _.cloneDeep(this.corpora);

    this.corporaFiltering = corporaList.map(corpora => {
      if (!this.filterFields.some(field => field.value != ''))
        return corpora;

      corpora.fragments = corpora.fragments || [];
      corpora.fragments = corpora.fragments.filter(fragment => {
        const filteredLabels = this.filterFields.filter(e => e.name !== 'DISCUSSION');

        // filter discussion
        if (this.filterFields[5].value) {
          const discussion = fragment.discussion ? fragment.discussion.toLowerCase() : '';
          if (discussion.indexOf(this.filterFields[5].value.toLowerCase()) > -1)
            return true;

          const isFilterLabel = filteredLabels.some(field => field.value != '');
          if (!isFilterLabel)
            return false;
        }

        // filter labels
        const filterValues = filteredLabels.filter(field => field.value);
        let totalMatched = filteredLabels.filter(field => {
          const searchText = field.value ? field.value.toLowerCase().trim() : '';
          if (!searchText) {
            return false;
          }

          if (!fragment.labelIds || !fragment.labelDescriptions)
            return false;

          return fragment.labelIds.split(',').some(indexValue => {
            const existInDescription = fragment.labelDescriptions.toLowerCase().split(',').some(description => description.trim().indexOf(searchText) > -1);
            return indexValue.toLowerCase() == searchText || existInDescription;
          });
        })

        return totalMatched.length == filterValues.length;
      })
      return corpora;
    }).filter(corpora => corpora.fragments.length > 0);
  }

  public inbrief(fragment: string): string {
    if (!fragment || fragment.length < 300)
      return fragment;
    else
      return fragment.substr(0, 300) + '...';
  }

  public bookmark(fr: TestFragment): void {
    this.selectedCorporaId = fr.corporaId;
    this.sessionService.bookmarkText(fr.fragment)
      .then((result: any) => {
        fr.bookmarked = !result || result.success !== false;
        let message = fr.bookmarked ? 'Bookmarked' : 'Bookmark failed!';
        this._snackBar.open(message, 'Close', {
          duration: 2000
        })
      })
  }

  public deleteAfterUserConfirmation($event: Event, fragmentId: number): void {
    this._service.deleteTestfragment(fragmentId).then((response: LampUpdateResponse) => {
      if (response.success) {
        this.getFilteredData(this._lastSearchArgument, this._lastSearchArgumentType);
      }
    });
  }


  constructor(
    private _service: TestsetService,
    private _snackBar: MatSnackBar,
    protected sessionService: SessionService,
    protected translateService: TranslateService,
    protected localStorageHelper: LocalStorageHelper
  ) {
    super(translateService, localStorageHelper, sessionService);
  }

  storeResultBeforeLeaving(): void {
    if (!this.isNoFullRefresh) {
      this.localStorageHelper.clearTabSetting('corpora');
      return;
    }

    // store this.corpora to local storage before leaving
    if (this.corpora) {
      const currentState: State = {
        corpora: this.corpora,
        refineFilter: this.filterFields,
        searchArgumentType: this._lastSearchArgumentType,
        searchArgument: this._lastSearchArgument,
      }
      this.localStorageHelper.setTabSetting('corpora', JSON.stringify(currentState));
    }
  }

  loadLocalResultIfExist(): void {
    this.corpora = [];
    this.corporaFiltering = [];
    if (!this.isNoFullRefresh) {
      this.localStorageHelper.clearTabSetting('corpora');
      return;
    }

    // get this.corpora from local storage if exist
    const storageStr = this.localStorageHelper.getTabSetting('corpora');
    const state: State = storageStr ? JSON.parse(storageStr) : undefined;
    if (state && state.searchArgument === this._lastSearchArgument && state.searchArgumentType === this._lastSearchArgumentType) {
      this.corpora = state.corpora;
      this.filterFields = state.refineFilter;
    }
  }

  public changedNoRefresh(): void {
    this.localStorageHelper.setTabSetting('corpora_no_refresh', this.isNoFullRefresh ? 'true' : 'false');
  }

  public saveTheCurrentId(id: number) {
    this.showErrorMessage('');
    this.showingElementId = id;
    this.localStorageHelper.setTabSetting(this.idIndexKey, id.toString());
  }

  public getVisitElement(): void {
    const index = this.localStorageHelper.getTabSetting(this.idIndexKey);
    let visitedElementId = parseInt(decodeURIComponent(index), 10);
    visitedElementId = (!isNaN(visitedElementId) && visitedElementId) ? visitedElementId : 0;
    this.showingElementId = visitedElementId;
  }

  public refresh() {
    //clear cache
    this.localStorageHelper.clearTabSetting('corpora');

    this.getFilteredData(this._lastSearchArgument, this._lastSearchArgumentType);
  }
}
