import {Component, OnInit, OnDestroy, ChangeDetectorRef, Input, Output, EventEmitter} from '@angular/core';
import { Translated } from '../shared/classes/translated.class';
import { TranslateService, USE_DEFAULT_LANG } from '@ngx-translate/core';
import { SessionService } from '../shared/services/session.service';
import { LocalStorageHelper } from '../shared/helpers/localhost.helper';
import { TestsetService } from '../shared/services/testset.service';
import { TestStats, OneLevelDateStat, ThroughputMetric, TestMetric, PhraseFamilyDateStat, PhraseTestFailure, ResultDifference, TestResult, FunctionalityStats, PhraseTestTotals } from '../shared/models/test.model';
import { Options } from 'ng5-slider';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { MatSnackBar, MatTabChangeEvent } from '@angular/material';
import { Location } from '@angular/common';

declare const require: any;

interface CharAreaStyle {
  normal: any[];
}

/**
 * The Y series data
 */
interface ChartYSeries {
  name: string, // e.g. 'Precision'
  type: string, // e.g. 'line'
  stack?: string, // e.g. 'counts'
  areaStyle?: CharAreaStyle, // e.g. { normal: {} },
  data: number[] // e.g. [120, 132, 101, 134, 90, 230, 210]
  markLine?: ChartMarkline;
  symbolSize?: number; // e.g. 10
  tooltip?: {
    show?: boolean;
  }
}

/**
 * The X axis
 */
interface ChartXAxis {
  type: string;
  boundaryGap?: boolean;
  data: any[];
}

interface ChartMarkline {
  symbol: 'none',
  label: {
    show: true,
    position: string, //'end'
    formatter?: string // the actual label
  },
  lineStyle: {
    color: '#e54035',
    width: 2
  },
  data: [{
          yAxis: number
        }]
}

interface ChartOptions {
  tooltip?;
  grid?;
  yAxis?;
  xAxis?: ChartXAxis[];
  series?: ChartYSeries[];
  markLine?: ChartMarkline;
}

enum FailStage {
  TRIGGERING = 'triggering',
  MATCHING = 'matching',
  REQUIREMENT_VALIDATION = 'requirement_validation',
  COMPETITION = 'competition'
}

const initialLoadingState = {
  unknownStats: null,
  metrics: null,
  coverage: null,
  fragmented: null,
  topPhrases: null,
  phraseCoverage: null,
  phraseTestTotals: null,
  throughput: null
};

@Component({
  selector: 'result-stats',
  templateUrl: './testresults.component.html',
  styleUrls: ['./testresults.component.less']
})
export class TestResultsComponent extends Translated implements OnInit {
  private _defaultMarkLine: ChartMarkline = {
    symbol: 'none',
    label: {
      show: true,
      position: 'middle'
    },
    lineStyle: {
      color: '#e54035',
      width: 2
    },
    data: [{
            yAxis: 0
          }]
  };

  
  public abuseDiffSliderOptions: Options = {
    floor: 0,
    ceil: 10,
    minRange: 0,
    minLimit: 0,
    translate: (value: number): string => {
      if (value > 0 && value <= this.abuseDates.length)
        return this.abuseDates[value - 1];
      else
        return ' ';
    }
  };
  
  public sentimentDiffSliderOptions: Options = {
    floor: 0,
    ceil: 10,
    minRange: 0,
    minLimit: 0,
    translate: (value: number): string => {
      if (value > 0 && value <= this.sentimentDates.length)
        return this.sentimentDates[value - 1];
      else
        return ' ';
    }
  };
  
  public entityDiffSliderOptions: Options = {
    floor: 0,
    ceil: 10,
    minRange: 0,
    minLimit: 0,
    translate: (value: number): string => {
      if (value > 0 && value <= this.entityDates.length)
        return this.entityDates[value - 1];
      else
        return ' ';
    }
  };
  
  public topicDiffSliderOptions: Options = {
    floor: 0,
    ceil: 10,
    minRange: 0,
    minLimit: 0,
    translate: (value: number): string => {
      if (value > 0 && value <= this.topicDates.length)
        return this.topicDates[value - 1];
      else
        return ' ';
    }
  };

  /**
   * Instance of the abuse detection chart
   */
  public abuseChart; 
  /**
   * Instance of the sentiment detection chart
   */
  public sentimentChart;
  /**
   * Instance of the entity detection chart
   */
  public entityChart;
  /**
   * Instance of the topic detection chart
   */
  public topicChart;
  /**
   * Instance of the % of unknown words chart
   */
  public unknownChart;
  /**
   * Instance of the top phrase chart
   */
  public topPhraseChart;
  /**
   * Instance of the sentence coverage chart
   */
  public coverageChart;
  /**
   * Instance of the share of poorly mapped / fragmented sentence chart
   */
  public fragmentedChart;
  /**
   * Instance of the WPS chart
   */
  public wpsChart;
  /**
   * Instance of the CPS chart
   */
  public cpsChart;

  public stats: TestStats;

  public lastFrom: number = 0;
  public lastTo: number = 0;

  public fromDateAbuse: number = 0;
  public toDateAbuse: number = 0;
  public fromDateSentiment: number = 0;
  public toDateSentiment: number = 0;
  public fromDateEntities: number = 0;
  public toDateEntities: number = 0;
  public fromDateTopics: number = 0;
  public toDateTopics: number = 0;
  public sampleDateAbuse: number = 0;
  public sampleDateSentiment: number = 0;
  public sampleDateEntities: number = 0;
  public sampleDateTopics: number = 0;

  private _lastRange: string;


  public failedPhraseTestCount: number;
  public totalPhraseTests: number;
  public failedPhraseExamples: PhraseTestFailure[];
  public showedFailedPhraseExamples: PhraseTestFailure[];

  public abuseDates: string[] = [];
  public sentimentDates: string[] = [];
  public entityDates: string[] = [];
  public topicDates: string[] = [];
  public diffAbuse: ResultDifference;
  public diffSentiment: ResultDifference;
  public diffEntity: ResultDifference;
  public diffTopic: ResultDifference; 
  public newFailuresOnly: boolean = false;
  public lostCompetition: boolean = false;
  public noRequiredPhrases: boolean = false;
  public didNotMatch: boolean = false;
  public wasNotTriggered: boolean = false;
  private searchArgument: string;
  public showExpansion = true;
  public isLoading = { ...initialLoadingState };
  public filterPhraseList = [
    {
      key: FailStage.TRIGGERING,
      label: 'TEST.was-not-triggered',
      currentValue: false
    },
    {
      key: FailStage.MATCHING,
      label: 'TEST.did-not-match',
      currentValue: false
    },
    {
      key: FailStage.REQUIREMENT_VALIDATION,
      label: 'TEST.no-required-phrases',
      currentValue: false
    },
    {
      key: FailStage.COMPETITION,
      label: 'TEST.lost-competition',
      currentValue: false
    }
  ];

  public sliderRefresh = new Subject<void>();

  public _commonChartOptions: ChartOptions = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    yAxis: [
      {
        type: 'value'
      }
    ],
    xAxis: [{
      type: 'category', data: []
    }],
    series: []
  };

  public abuseOptions: ChartOptions = {

  };

  public sentimentOptions: ChartOptions = {

  };
 
  public entitiesOptions: ChartOptions = {

  };
 
  public topicOptions: ChartOptions = {

  };
 
  public unknownOptions: ChartOptions = {

  };
 
  public topPhraseOptions: ChartOptions = {

  };
 
  public coverageOptions: ChartOptions = {

  };
 
  public fragmentedOptions: ChartOptions = {

  };

  public wpsOptions: ChartOptions = {

  };

  public cpsOptions: ChartOptions = {

  };

  constructor(
    protected sessionService: SessionService,
    protected translateService: TranslateService,
    protected localStorageHelper: LocalStorageHelper,
    protected _service: TestsetService,
    private snackBar: MatSnackBar,
    private cdRef: ChangeDetectorRef,
    private location: Location
 ) 
  { 
      super(translateService, localStorageHelper, sessionService);
      let jsonCommonOptions: string = JSON.stringify(this._commonChartOptions)
      this.coverageOptions = JSON.parse(jsonCommonOptions);
      this.fragmentedOptions = JSON.parse(jsonCommonOptions);
      this.topPhraseOptions = JSON.parse(jsonCommonOptions);
      this.unknownOptions = JSON.parse(jsonCommonOptions);
  
      this.abuseOptions = JSON.parse(jsonCommonOptions);
      this.entitiesOptions = JSON.parse(jsonCommonOptions);
      this.sentimentOptions = JSON.parse(jsonCommonOptions);
      this.topicOptions = JSON.parse(jsonCommonOptions);

      this.wpsOptions = JSON.parse(jsonCommonOptions);
      this.cpsOptions = JSON.parse(jsonCommonOptions);
    }

  ngOnInit() {
    this.initFromSession();
    this.setDefaultFilter();
  }

  private setDefaultFilter() {
    this.location.go('/test-overview?key=range&value=');
  }

  public getSliderOptions(type: string): Options {
    switch (type) {
      case 'abuse': return this.abuseDiffSliderOptions;
      case 'sentiment': case 'sentiment_expressions': return this.sentimentDiffSliderOptions;
      case 'entity': return this.entityDiffSliderOptions;
      //case 'topic': return this.topicDiffSliderOptions;
    }
    return undefined;
  }
  
  public resultText(fragment: string, r: TestResult, name: string, maxLen?: number): string {
    return this._service.resultText(fragment, r, name, maxLen);
  }

  public verbEmoji(verb: string): string {
    switch (verb.toUpperCase()) {
      case 'PUT': return '📝';
      case 'POST': return '➕';
      case 'DELETE': return '➖';
      default: return verb;
    }
  }

  private getDatesByType(attributeName: string): string[] {
    switch(attributeName) {
      case 'abuse': return this.abuseDates;
      case 'sentiment_expressions': case 'sentiment': return this.sentimentDates;
      case 'entity': return this.entityDates;
      case 'topic': return this.topicDates;
    }
    return undefined;
  }
  
  /**
   * Generates the diff between the two dates. 
   * @param fromDate 
   * @param toDate 
   * @param attributeName 
   */
  public generateDiff(fromDate: number, toDate: number, attributeName: string): void {
    let availabledates: string[] = this.getDatesByType(attributeName);
    if (!availabledates)
      return;
    this.lastFrom = fromDate;
    this.lastTo = toDate;

    if (fromDate === 0)
      fromDate = 1;

    this._service.diff(availabledates[fromDate - 1].replace('/','-'), availabledates[toDate - 1].replace('/','-'), 
        attributeName, this._lastRange).then((rd: ResultDifference) => 
        {
          switch(attributeName) {
            case 'abuse': this.diffAbuse = rd;
              break;
            case 'sentiment_expressions': case 'sentiment': this.diffSentiment = rd;
              break;
            case 'entity': this.diffEntity = rd;
              break;
            case 'topic': this.diffTopic = rd;
              break;
          }

        });
  }

  private createMarkLine(y: number, label?: string): ChartMarkline {
    let ml: ChartMarkline = JSON.parse(JSON.stringify(this._defaultMarkLine));
    ml.data[0].yAxis = y;
    if (label)
      ml.label.formatter = label;
    return ml;
  }

  initThisSubtypeFromSession(): void {
      this.loadData({key: '', value: '', description: ''});
  }

  protected setChartSeries(options: ChartOptions, series: OneLevelDateStat[], seriesName: string, chartInstance): void {
    let dates: string[] = [];
    let yValues: number[] = [];
    for (let sr of series)
    {
      dates.push(sr.Key);
      yValues.push(sr.Value.key / sr.Value.value * 100);
    }
    if (chartInstance && dates.length > 0 && yValues.length > 0) {
      this.setChartData(options, dates, yValues, seriesName);


      //prevent markline out of chart
      const maxPoint = _.max(yValues);
      if (options.markLine) {
        const maxMarkline = _.max(options.markLine.data, 'yAxis').yAxis;
        if (maxMarkline > maxPoint) {
          options.series.push({
            name: '',
            type: 'line',
            data: [maxMarkline * 1.2],
            symbolSize: 0,
            tooltip: {
              show: false
            }
          })
        }
      }

      chartInstance.setOption(options);
    }
  }

  protected setChartThroughputSeries(options: ChartOptions, series: ThroughputMetric[], seriesName: string, chartInstance): void {
    let dates: string[] = [];
    let yValues: number[] = [];
    for (let sr of series)
    {
      dates.push(sr.Key);
      yValues.push(sr.Value);
    }
    if (chartInstance && dates.length > 0 && yValues && yValues.length > 0) {
      this.setChartData(options, dates, yValues, seriesName);
      chartInstance.setOption(options);
    }
  }

  public topPhraseClick($event): void {
    alert($event);
  }

  protected setTopPhrasesSeries(options: ChartOptions, series: PhraseFamilyDateStat[], seriesName: string, chartInstance) {
    let dates: string[] = [];
    let topIdsByFamily: Map<number, number[]> = new Map<number, number[]>();
    let phraseIdsByFamily: Map<number, string> = new Map<number, string>();
    let dayIndex: number = 0;
    for (let sr of series)
    {
      dates.push(sr.Key);
      if (!sr.Value)
        continue;
      for (let familyStats of sr.Value) {
        let familyId: number = familyStats.Key;
        let targetArr: number[];
        let idList: string;
        if (topIdsByFamily.has(familyId)) {
          targetArr = topIdsByFamily.get(familyId);
          idList = phraseIdsByFamily.get(familyId);
        } else {
          targetArr = [];
          topIdsByFamily.set(familyId, targetArr);
        }
        let sumOfAllPhrasesInFamily: number = 0;

        sumOfAllPhrasesInFamily += familyStats.Value;
        targetArr.push(sumOfAllPhrasesInFamily);
        phraseIdsByFamily.set(familyId, idList);
      }
      dayIndex++;
      for (let phFamilyId in topIdsByFamily.keys()) {
        let phStat: number[] = topIdsByFamily.get(parseInt(phFamilyId));
        if (phStat.length < dayIndex)
          phStat.push(0);
      }
    }
    if (dates.length > 0 && topIdsByFamily.size > 0) {
      this.pushXAxis(options, dates);
      options.series = [];
      topIdsByFamily.forEach((phStat: number[], phFamilyId: number) => {
        let phraseIds: string = phraseIdsByFamily.get(phFamilyId);
        let phraseLabel: string;
        if (phraseIds)
          phraseLabel = phFamilyId.toString() + ' (' + phraseIds + ')';
        else
          phraseLabel = phFamilyId.toString();
        options.series.push(
          this.createChartYSeries(phraseLabel, phStat));

      });
      if(chartInstance) {
        chartInstance.setOption(options);
      }
    }
    
  }

  protected setChartMetricSeries(targetChart, options: ChartOptions,
      series: TestMetric[], fn: string, chartTitle: string): void {
    let dates: string[] = [];
    let accuracy: number[] = [];
    let f1: number[] = [];
    let precision: number[] = [];
    let recall: number[] = [];
    for (let sr of series)
    {
      dates.push(sr.Key);
      for (let fs of sr.Value) {
        if (fs.function === fn) {
          accuracy.push(fs.accuracy * 100);
          f1.push(fs.f1 * 100);
          precision.push(fs.precision * 100);
          recall.push(fs.recall * 100);
        }
      }
    }

    switch (fn) {
      case 'abuse': this.abuseDates = dates;
        // https://angular-slider.github.io/ng5-slider/demos#dynamic-options-slider: need to recreate (!!!)
        this.abuseDiffSliderOptions = Object.assign({}, this.abuseDiffSliderOptions);
        this.abuseDiffSliderOptions.ceil = dates.length;
        break;
      case 'sentiment': case 'sentiment_expressions': this.sentimentDates = dates;
        // https://angular-slider.github.io/ng5-slider/demos#dynamic-options-slider: need to recreate (!!!)
        this.sentimentDiffSliderOptions = Object.assign({}, this.sentimentDiffSliderOptions);
        this.sentimentDiffSliderOptions.ceil = dates.length;
        break;
      case 'entities': case 'entity': this.entityDates = dates;
        // https://angular-slider.github.io/ng5-slider/demos#dynamic-options-slider: need to recreate (!!!)
        this.entityDiffSliderOptions = Object.assign({}, this.entityDiffSliderOptions);
        this.entityDiffSliderOptions.ceil = dates.length;
        break;
      /*
        case 'topic': this.topicDates = dates;
      this.topicDiffSliderOptions = Object.assign({}, this.topicDiffSliderOptions);
      this.topicDiffSliderOptions.ceil = dates.length;
      break;
      */
    }
    //this.sliderRefresh.next();
        
    this.pushXAxis(options, dates);

    options.series = [];
    options.series.push(
      this.createChartYSeries(this.translateService.instant('TEST.precision'), precision));
    options.series.push(
      this.createChartYSeries(this.translateService.instant('TEST.recall'), recall));
    options.series.push(
      this.createChartYSeries(this.translateService.instant('TEST.f1-score'), f1));
    options.series.push(
      this.createChartYSeries(this.translateService.instant('TEST.accuracy'), accuracy));
    if(targetChart) {
      targetChart.setOption(options);              
    }
  }

  /**
   * Chart lib will crash when it dont have value of xAxis so we added an value when init chart
   * and remove it when have the real data to load to chart
   */
  private pushXAxis(options: ChartOptions, dates: string[]) {
    if (options.xAxis.length == 1 && options.xAxis[0].data.length == 0) {
      options.xAxis = [];
    }
    options.xAxis.push(this.createCharXAxis(dates));
  }

  protected createCharXAxis(data: string[]): ChartXAxis {
    let newXAxis: ChartXAxis = {type: 'category', data: data};
    return newXAxis;
  }

  protected createChartYSeries(name: string, data: number[]): ChartYSeries {
    let newSeries: ChartYSeries = {
      type: 'line',
      //stack: 'counts',
      name: name,
      data: data};
    return newSeries;
  }

  protected setChartData(options: ChartOptions, dates: string[], series: number[], seriesName: string): void {
    this.pushXAxis(options, dates);
    options.xAxis.push(this.createCharXAxis(dates));
    let srs = this.createChartYSeries(this.translateService.instant(seriesName), 
    series);
    if (options.markLine)
      srs.markLine = options.markLine;
    options.series.push(srs);
  }

  
  public getMetric(dateIndex: number, type: string): FunctionalityStats {
    let availableDates: string[] = this.getDatesByType(type);
    if (!availableDates || dateIndex < 1 || dateIndex > availableDates.length)
      return undefined;
    let date: string = availableDates[dateIndex - 1];
    for (let metric of this.stats.metrics) {
      if (metric.Key === date) {
        for (let fs of metric.Value) {
          if (fs.function === type) {
            if (type == 'abuse') {
              fs.incorrectSample = this.sort(fs.incorrectSample);
              fs.missingSample = this.sort(fs.missingSample);
            }
            return fs;
          }
        }
      }
    }
    return undefined;
  }

  protected getFilteredData(searchArgument: string, searchArgumentType: string): Promise<void> {
    this.searchArgument = searchArgument;
    this.showExpansion = false;
    this.cdRef.detectChanges();
    this.showExpansion = true;
    this.isLoading = { ...initialLoadingState };
    this._lastRange = searchArgument;
    return Promise.resolve();
  }

  protected getFilteredDataBk(searchArgument: string, searchArgumentType: string): Promise<void> {
    this.searchArgument = searchArgument;
    return this._service.stats(searchArgument)
    .then((teststats: TestStats) => {
      this.stats = teststats;
      this._lastRange = searchArgument;
      this._service.phraseFailureList().then((failures: PhraseTestFailure[]) => {
        this.failedPhraseExamples = _.cloneDeep(failures.map(failure => {
          const filterSetting = this.filterPhraseList.find(x => x.key == failure.failStage);
          failure.failStageDisplay = filterSetting ? filterSetting.label : this.filterPhraseList[0].label;
          return failure;
        }));
        this.localFilter();
      });    
  
      this.setChartMetricSeries(this.abuseChart, this.abuseOptions,  
        teststats.metrics, 'abuse', 'TEST.abuse');
      this.setChartMetricSeries(this.sentimentChart, this.sentimentOptions, 
        teststats.metrics, 'sentiment_expressions', 'TEST.sentiment');
      this.setChartMetricSeries(this.entityChart, this.entitiesOptions,
        teststats.metrics, 'entity', 'TEST.entities');
      //this.setChartMetricSeries(this.topicChart, this.topicOptions, teststats.metrics, 'topic', 'TEST.topics');
      this.setTopPhrasesSeries(this.topPhraseOptions, teststats.topPhrases, 'TEST.top-phrases', this.topPhraseChart);
      this.setChartThroughputSeries(this.wpsOptions, teststats.wps, 'TEST.wps', this.wpsChart);
      this.setChartThroughputSeries(this.cpsOptions, teststats.cps, 'TEST.cps', this.cpsChart);
      this.unknownOptions.markLine = this.createMarkLine(3, 
        this.translateService.instant('TEST.unknown-very-high-amount'));
      this.setChartSeries(this.unknownOptions, teststats.unknown, 'TEST.unknown-share', this.unknownChart);
      this.coverageOptions.markLine = this.createMarkLine(90, this.translateService.instant('TEST.enough-coverage'));
      //this.coverageOptions.yAxis[0].max = 100;
      this.setChartSeries(this.coverageOptions, teststats.coverage,'TEST.coverage', this.coverageChart);
      this.setChartSeries(this.fragmentedOptions, teststats.fragmented, 'TEST.fragmented', this.fragmentedChart);
      this.failedPhraseTestCount = teststats.failedPhraseTests;
      this.totalPhraseTests = teststats.totalPhraseTests;
    });
  }

  public async getStats(statName: 'metrics' | 'unknownStats' | 'fragmented' | 'topPhrases' | 'coverage' | 'phraseTestTotals' | 'throughput') {
    if (this.isLoading[statName] === null) {
      try {
        this.isLoading[statName] = true;
        this.stats = this.stats || {} as TestStats;
        switch (statName) {
          case 'metrics':
            const metrics: TestMetric[] = await this._service.metrics(this.searchArgument || '');
            this.stats = { ...this.stats, metrics };
            this.setChartMetricSeries(this.abuseChart, this.abuseOptions,
              metrics, 'abuse', 'TEST.abuse');
            this.setChartMetricSeries(this.sentimentChart, this.sentimentOptions,
              metrics, 'sentiment_expressions', 'TEST.sentiment');
            this.setChartMetricSeries(this.entityChart, this.entitiesOptions,
              metrics, 'entity', 'TEST.entities');
            break;
          case 'unknownStats':
            const unknown: OneLevelDateStat[] = await this._service.unknownWords(this.searchArgument || '');
            this.stats = { ...this.stats, unknown };
            this.unknownOptions.markLine = this.createMarkLine(3,
              this.translateService.instant('TEST.unknown-very-high-amount'));
            this.setChartSeries(this.unknownOptions, this.stats.unknown, 'TEST.unknown-share', this.unknownChart);
            break;

          case 'fragmented':
            const fragmented: OneLevelDateStat[] = await this._service.manyPhraseRoots(this.searchArgument || '');
            this.stats = { ...this.stats, fragmented };
            this.setChartSeries(this.fragmentedOptions, this.stats.fragmented, 'TEST.fragmented', this.fragmentedChart);
            break;

          case 'topPhrases':
            const topPhrases: PhraseFamilyDateStat[] = await this._service.topPhrases(this.searchArgument || '');
            this.stats = { ...this.stats, topPhrases };
            this.setTopPhrasesSeries(this.topPhraseOptions, this.stats.topPhrases, 'TEST.top-phrases', this.topPhraseChart);
            break;

          case 'coverage':
            const coverage: OneLevelDateStat[] = await this._service.phraseCoverage(this.searchArgument || '');
            this.stats = { ...this.stats, coverage };
            this.coverageOptions.markLine = this.createMarkLine(90, this.translateService.instant('TEST.enough-coverage'));
            this.setChartSeries(this.coverageOptions, this.stats.coverage, 'TEST.coverage', this.coverageChart);
            break;
          case 'phraseTestTotals':
            const result: PhraseTestTotals = await this._service.phraseTestTotals(this.searchArgument || '');
            this.stats = { ...this.stats, failedPhraseTests: result.failedPhraseTests, totalPhraseTests: result.totalPhraseTests };
            this.failedPhraseTestCount = this.stats.failedPhraseTests;
            this.totalPhraseTests = this.stats.totalPhraseTests;

            const failures: PhraseTestFailure[] = await this._service.phraseFailureList()
            this.failedPhraseExamples = _.cloneDeep(failures.map(failure => {
              const filterSetting = this.filterPhraseList.find(x => x.key == failure.failStage);
              failure.failStageDisplay = filterSetting ? filterSetting.label : this.filterPhraseList[0].label;
              return failure;
            }));
            this.localFilter();
            break;
          case 'throughput':
            const cps: ThroughputMetric[] = await this._service.throughput(this.searchArgument || '');
            this.stats = { ...this.stats, cps };
            this.setChartThroughputSeries(this.cpsOptions, this.stats.cps, 'TEST.cps', this.cpsChart);
            break;
        }

      } catch (error) {
        this.snackBar.open(error.message || error);
      } finally {
        this.isLoading[statName] = false;
      }
    }
  }

  public onParsingTabChanged(event: MatTabChangeEvent) {
    switch (event.tab.ariaLabel) {
      case 'unknownStats':
        return this.getStats('unknownStats');
      case 'topPhrases':
        return this.getStats('topPhrases');
      case 'coverage':
        return this.getStats('coverage');
      case 'fragmented':
        return this.getStats('fragmented');
      case 'phraseTestTotals':
        return this.getStats('phraseTestTotals');
    }
  }

  public linkChartInstance($event,targetChart) {
    targetChart = $event;
  }

  protected filterSettingName(): string {
    return 'teststats'; // used to save and restore the filter
  }

 
  public options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    yAxis: [
      {
        type: '%'
      }
    ],
    xAxis: [
      {
        type: 'category',
        boundaryGap: false
        //,data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'] // dates
      }
    ],
    legend: {
      data: ['Precision', 'Recall', 'F1', 'Accuracy', 'X-5']
    },
    series: [
      {
        name: 'Precision',
        type: 'line',
        stack: 'counts',
        areaStyle: { normal: {} },
        data: [120, 132, 101, 134, 90, 230, 210]
      },
      {
        name: 'Recall',
        type: 'line',
        stack: 'counts',
        areaStyle: { normal: {} },
        data: [220, 182, 191, 234, 290, 330, 310]
      },
      {
        name: 'F1',
        type: 'line',
        stack: 'counts',
        areaStyle: { normal: {} },
        data: [150, 232, 201, 154, 190, 330, 410]
      },
      {
        name: 'Accuracy',
        type: 'line',
        stack: 'counts',
        areaStyle: { normal: {} },
        data: [320, 332, 301, 334, 390, 330, 320]
      },
      {
        name: 'X-5',
        type: 'line',
        stack: 'counts',
        label: {
          normal: {
            show: true,
            position: 'top'
          }
        },
        areaStyle: { normal: {} },
        data: [820, 932, 901, 934, 1290, 1330, 1320]
      }
    ]
  };

  private sort(list: TestResult[]) {
    const order = ['profanity', 'adult_only', 'external_contact', 'bigotry', 'personal_attack', 'criminal_activity', 'mental_issues', 'allegation'];
    var existedNomeaning = false;
    list.forEach(sample => {
      if (order.indexOf(sample.label) == -1) {
        if (sample.label == 'no_meaningful_content')
          existedNomeaning = true;

        else
          order.push(sample.label);
      }
    });
    if (existedNomeaning) {
      order.push('no_meaningful_content');
    }

    list = list.map(sample => {
      sample.groupIndex = order.indexOf(sample.label);
      sample.display = this.resultText(sample.fragment, sample, '', 200);
      return sample;
    });

    return list.sort((a, b) => (a.groupIndex > b.groupIndex) ? 1 : (a.groupIndex === b.groupIndex) ? (a.display.length - b.display.length) : -1);
  }

  public localFilter() {
    const wasNotTriggered = this.filterPhraseList.find(x => x.key == FailStage.TRIGGERING).currentValue;
    const didNotMatch = this.filterPhraseList.find(x => x.key == FailStage.MATCHING).currentValue;
    const noRequiredPhrases = this.filterPhraseList.find(x => x.key == FailStage.REQUIREMENT_VALIDATION).currentValue;
    const lostCompetition = this.filterPhraseList.find(x => x.key == FailStage.COMPETITION).currentValue;

    if(!wasNotTriggered && !didNotMatch && !noRequiredPhrases && !lostCompetition) {
      this.showedFailedPhraseExamples = _.cloneDeep(this.failedPhraseExamples);
      return;
    }

    this.showedFailedPhraseExamples = _.cloneDeep(this.failedPhraseExamples).filter((example: PhraseTestFailure) => {
      return (wasNotTriggered && example.failStage == FailStage.TRIGGERING) || 
      (didNotMatch && example.failStage == FailStage.MATCHING) || 
      (noRequiredPhrases && example.failStage == FailStage.REQUIREMENT_VALIDATION) || 
      (lostCompetition && example.failStage == FailStage.COMPETITION)
    })

  }

  downloadAccuracyInCSV(dateIndex: number, type: string) {
    const metrics = this.getMetric(dateIndex, type);
    const header = [
      this.translateService.instant('TEST.error-text'),
      this.translateService.instant('TEST.snippet'),
      this.translateService.instant('TEST.label'),
      this.translateService.instant('TEST.error-type')];

    const labelMappings = {
      abuse: this.translateService.instant('TEST.abuse'),
      sentiment_expressions: this.translateService.instant('TEST.sentiment'),
      entity: this.translateService.instant('TEST.entities')
    }

    const rows = [];
    metrics.missingSample.forEach(missing => {
      rows.push([
        this.resultText(missing.fragment, missing, '', 200),
        missing.fragment,
        missing.label,
        'missing'
      ]);
    });

    metrics.incorrectSample.forEach(incorrect => {
      rows.push([
        this.resultText(incorrect.fragment, incorrect, '', 200),
        incorrect.fragment,
        incorrect.label,
        'incorrect'
      ]);
    });

    this.csvGenerator(header, rows, `accuracy-${(labelMappings[type] || '').toLowerCase()}`);
  }

  downloadFailPhraseInCSV() {
    const header = [
      this.translateService.instant('TEST.snippet'),
      this.translateService.instant('TEST.family-id'),
      this.translateService.instant('TEST.phrase-id'),
      this.translateService.instant('TEST.fail-stage'),
      this.translateService.instant('TEST.fail-reason'),
      this.translateService.instant('TEST.should-not-contain')];

    const rows = this.failedPhraseExamples.map((row) =>
      [
        row.snippet,
        row.family,
        row.id,
        this.translateService.instant(row.failStageDisplay),
        row.failReason,
        row.positive ? this.translateService.instant('COMMON.no') : this.translateService.instant('COMMON.yes')
      ]
    );

    this.csvGenerator(header, rows, 'failedphrasetests');
  }

  private csvGenerator(header: string[], csv: (string | number)[][], fileName: string) {
    header = header.map(cellValue => this.escapeText(cellValue));
    csv = csv.map(row => row.map(celValue => this.escapeText(celValue)));

    csv.unshift(header.join(',') as any);

    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob(["\uFEFF" + csvArray], { type: 'text/csv; charset=utf-18' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = this.getFileName(fileName);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  private getFileName(prefix: string) {
    const today = new Date();
    const date = `${today.getFullYear().toString().substr(2, 2)}${("0" + (today.getMonth() + 1).toString()).slice(-2)}${("0" + (new Date()).getDate().toString()).slice(-2)}`;
    return `${prefix}-${this.sessionService.languageISOCode()}-${date}.csv`;
  }

  escapeText(input: string | number = ''): string {
    return `"${input.toString().replace(/\"/g, '""')}"`;
  }
}