import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup, FormBuilder} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';

import {TranslateService} from '@ngx-translate/core';

import {Subscription} from 'rxjs/Subscription';

import {Translated} from '../../../shared/classes/translated.class';
import {SessionService} from '../../../shared/services/session.service';
import {RouterHelper} from '../../../shared/helpers/router.helper';
import {Ranges} from '../../../shared/models/session';
import {toJsonObject} from '../../../shared/helpers/object.helper';
import { LocalStorageHelper } from '../../../shared/helpers/localhost.helper';
import { FilterSetting } from '../../../shared/models/common';

/**
 * This appears to be the top level component of the filter module.
 */

@Component({
  selector: 'app-universal-id-text-range-filter',
  templateUrl: './universal-id-text-range-filter.component.html',
  styleUrls: ['./universal-id-text-range-filter.component.less']
})

export class UniversalIdTextRangeFilterComponent extends Translated implements OnInit, OnDestroy {
  @Output() chosenParameterEvent: EventEmitter<FilterSetting> =
    new EventEmitter();

  @Input() actionButtonTextKey: string;
  @Input() actionButtonTooltipKey: string;
  @Input() disableNavigation: boolean;
  @Input() currentRange: string;
  @Input() overrideRecentIdsTableName: string;
  @Input() allowOtherQueryParameters: boolean;
  @Input() loadedRecordCount: number;
  @Input() loadedRecordCountLabel;
  @Input() loadedSubRecCount: number = 0;
  @Input() loadedSubRecCountLabel: string;
  @Output() actionButtonPressedEvent: EventEmitter<string> = new EventEmitter();

  public predeterminedValue: string | number;
  public predeterminedKey: string;

  private routeSubscription: Subscription;
  public id: string;
  public text: string;
  public range: Ranges[];
  public rangeKey: number;
  public filterForm: FormGroup;

  public filterInputParametersNames: string[];
  public filterSelectParametersNames: string[];
  public filterSelectParametersValues: { key?: string | number, value?: string }[] = [];

  public anotherParameters: {};
  public lastEmitParams: string;

  constructor(private formBuilder: FormBuilder,
              protected translateService: TranslateService,
              protected localStorageHelper: LocalStorageHelper,
              protected sessionService: SessionService,
              private routerHelper: RouterHelper,
              private route: ActivatedRoute) {
    super(translateService, localStorageHelper, sessionService);
  }

  public ngOnInit(): void {
    this.createForm();
    this.subscribeOnRouteChanges();
    this.initFromSession();
    //this.subscribeToSession();
  }

  public tableName(): string {
    if (this.overrideRecentIdsTableName && this.overrideRecentIdsTableName.length > 0)
      return this.overrideRecentIdsTableName;
    else
      return this.currentRange;
  }

  initThisSubtypeFromSession(): void {
    this.getRangesForCurrentLanguage();
  }
  
  ngOnDestroy(): void {
    super.unsubscribeFromSessionIfSubscribed();
    if (!this.disableNavigation) {
      this.routeSubscription.unsubscribe();
    }
  }

  private subscribeOnRouteChanges(): void {
    if (!this.disableNavigation) {
      this.routeSubscription = this.route
        .queryParams
        .subscribe(routeParameters => {
          this.saveOtherParameters(routeParameters);
          const parameters = routeParameters as { key: string, value: string | number };
          if (parameters.key && parameters.value) {
            this.predeterminedValue = parameters.value;
            this.predeterminedKey = parameters.key;
          }
        });
    }
  }

  private saveOtherParameters(routeParameters): void {
    if (this.allowOtherQueryParameters) {
      const anotherParameters = toJsonObject(routeParameters);
      if (anotherParameters.key) {
        delete anotherParameters.key;
      }
      if (anotherParameters.value) {
        delete anotherParameters.value;
      }

      this.anotherParameters = anotherParameters;
    }
  }

  private getRangesForCurrentLanguage(): void {
    if (!this.sessionService.session)
      return;
    const languageId = this.sessionService.session.targetLanguage.id;
    const rangeFromSession = this.sessionService.session.ranges;

    const filtrationRule = (range: Ranges) => {
      return (range.Key === this.currentRange);
    };

    if (Boolean(rangeFromSession)) {
      const range: Ranges[] = rangeFromSession.filter(filtrationRule);

      if (range[0]) {
        const subFiltrationRule = (subRange: { Key: string, Value: { description: string, where: string, language?: number } }) => {
          return (subRange.Value.language === languageId) || (subRange.Value.language === undefined);
        };
        const filteredSubRange = range[0].Value.filter(subFiltrationRule);

        range[0].Value = filteredSubRange;
        this.range = range;
        this.formFilterData();
      }
    }
  }

  private createForm(): void {
    this.filterForm = this.formBuilder.group({
      id: [this.id],
      range: [this.rangeKey],
      text: [this.text]
    });
  }

  private formFilterData(): void {
    this.filterInputParametersNames = ['id', 'text'];
    this.filterSelectParametersNames = ['range'];

    if (this.range && this.range.length) {
      for (let i = 0; i < this.range.length; i++) {
        const groupValues = this.range[i].Value;

        this.filterSelectParametersValues = this.filterSelectParametersValues ?
          this.filterSelectParametersValues : [];

        for (let j = 0; j < groupValues.length; j++) {
          const value: { key?: string | number, value?: string } = {};
          value.value = groupValues[j].Value.description;
          value.key = groupValues[j].Key;
          let inserted: boolean = false;
          for (let cfi = 0; cfi < this.filterSelectParametersValues.length; cfi++) {
            if (this.filterSelectParametersValues[cfi].value.toLowerCase() > value.value.toLowerCase()) {
              this.filterSelectParametersValues.splice(cfi, 0, value);
              inserted = true;
              break;
            }
          }

          if (!inserted)
            this.filterSelectParametersValues = this.filterSelectParametersValues.concat([value]);
        }
      }
    }
  }

  private navigateToTheSamePageWithQueryParameters($event: { key: string, value: string | number }): void {
    this.routerHelper.navigateToTheSamePageWithQueryParameters($event);
  }

  public reactOnChosenParameterEvent($event: FilterSetting): void {
    if ($event.key) {
      if (!this.disableNavigation) {
        const queryParameters = $event;
        if (this.allowOtherQueryParameters && this.anotherParameters) {
          const parameterKeys = Object.keys(this.anotherParameters);
          parameterKeys.forEach(key => {
            queryParameters[key] = this.anotherParameters[key];
          });
          if ($event.description)
            queryParameters['description'] = $event.description;
        }
        this.navigateToTheSamePageWithQueryParameters($event);
      }
      this.pushTheDataFurther($event);
    }
  }

  public forwardActionButtonPressedEvent($event): void {
    this.actionButtonPressedEvent.emit($event);
  }

  public pushTheDataFurther($event: { key: string, value: string | number }): void {
    // unknown reason but sometime it trigger multi time but user not do anything
    if ($event && JSON.stringify($event) == this.lastEmitParams) {
      console.log('duplicated event');
      return;
    } else {
      this.lastEmitParams = JSON.stringify($event);
    }

    const $updatedEvent: FilterSetting = {key: $event.key.toLowerCase(), value: $event.value};
    this.chosenParameterEvent.emit($updatedEvent);
  }
}
