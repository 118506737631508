import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {FeatureListDefinition} from '../../../shared/models/feature';
import {isArray} from '../../../shared/helpers/object.helper';

@Component({
  selector: 'app-feature-id-to-feature-id-relation',
  templateUrl: './feature-id-to-feature-id-relation.component.html',
  styleUrls: ['./feature-id-to-feature-id-relation.component.less']
})
export class FeatureIdToFeatureIdRelationComponent implements OnInit {
  @Input() relevantFeatureList: FeatureListDefinition[];
  @Input() doesTheWholeFeatureListRelevant: boolean;
  @Input() isOnlyOneFeature: boolean;
  @Input() indexesArrays: number[][];
  @Input() indexesArray: number[];

  @Output() updatedIndexesArraysEvent: EventEmitter<number[][]> = new EventEmitter<number[][]>();
  @Output() updatedIndexesArrayEvent: EventEmitter<number[]> = new EventEmitter<number[]>();

  public isArray = isArray;

  constructor() {
  }

  ngOnInit() {
  }

  public addPairOfControls(): void {
    this.indexesArrays.push([undefined, undefined]);
  }

  public removePairOfControls(pairIndex): void {
    this.indexesArrays.splice(pairIndex, 1);
  }

  private saveCurrentFeatureId($event: number, index: number, firstOrSecondIndex: number) {
    if (this.indexesArrays) {
      if (this.indexesArrays[index][firstOrSecondIndex] !== $event) {
        this.indexesArrays[index][firstOrSecondIndex] = $event;

        this.updatedIndexesArraysEvent.next(this.indexesArrays);
      }
    }
    if (this.indexesArray) {
      if (this.indexesArray[firstOrSecondIndex] !== $event) {
        this.indexesArray[firstOrSecondIndex] = $event;
        this.updatedIndexesArrayEvent.next(this.indexesArray);
      }
    }
  }

  public saveCurrentFirstFeatureId($event: number, index: number): void {
    this.saveCurrentFeatureId($event, index, 0);
  }

  public saveCurrentSecondFeatureId($event: number, index: number): void {
    this.saveCurrentFeatureId($event, index, 1);
  }
}
