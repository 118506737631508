import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterContentChecked, Pipe, PipeTransform, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { DomSanitizer, SafeHtml, SafeStyle } from '@angular/platform-browser';
import { FeatureTypeList, FeatureValue } from '../shared/models/feature';
import { isNumber } from 'util';
import * as _ from 'lodash';
import { LanguageService } from '../shared/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { Translated } from '../shared/classes/translated.class';
import { LocalStorageHelper } from '../shared/helpers/localhost.helper';
import { SessionService } from '../shared/services/session.service';
import { EntityType } from './tracer.enum';
import { FailureReason, LexicalLinkHypotheses, LexicalLinkSenses, SearchResult, TestResult, TisaneEntity, TisaneHypothesis, TisanePhraseMainMatch } from '../shared/models/test.model';
import * as circularJSON from 'circular-json';
import { PhrasesService } from '../shared/services/phrasal.service';
import { IdentityRolesService } from '../shared/services/identity-roles.service';

@Component({
  selector: 'app-tracer',
  templateUrl: './tracer.component.html',
  styleUrls: ['./tracer.component.less']
})
export class VisualTracerComponent extends Translated implements OnInit, OnDestroy, AfterContentChecked {
  initThisSubtypeFromSession(): void {
    // do nothing
  }
  private SKIP_STEP_COUNT = 500;
  private _lastTimeoutHandle;
  /**
   * Indicates whether a fast forward, rewind, etc. mode is active.
   */
  public _controlOperationInProgress: boolean = false;
  /**
   * Impacts control buttons, what's enabled, what's disabled
   */
  public _isPlaying: boolean = false;
  public _input: string = "";
  public _text: string = "";
  public _sentence: string = "";
  public _sentences: any[];
  public _silent: boolean = false;
  public _selectionColor: string = "orange";
  public _contentBeforeHighlight: string = "";
  public _contentInsideHighlight: string = "";
  public _contentAfterHighlight: string = "";
  public _maxSegmentationCount: number = 1;
  public _lines: string[] = [''];
  public _totalSentenceSpan: number = 0;
  private _intervalTimer;
  public _currentLineIndex: number = 0;
  public _progress: number = 0;
  public _operationOutcome: string;
  public _outcomeReason: string;
  public _stepDelay: number;
  public _currentTitleParts: string[] = [];
  public _currentSection: string;
  public _previousSection: string;
  public _entityGroupTypes: string[] = [];
  public _constituents: any[] = [];
  // the number is the line, which may not be the same as the level number
  // The phrases intersecting with other phrases are banished to lower levels
  public _phrases: any[] = [];
  private _selectedNode: number = 0;
  public _highlighted: any = undefined;
  public _searchMatches: SearchResult[] = [];
  public _searchOverflow: string = "";
  public _currentParse: any = undefined;
  public _topics: any[];
  public _abuse: any[];
  public _sentiment: any[];

  private _phraseLevel: number = 0;
  private _families: Map<number, any> = new Map<number, any>();
  public _entitiesByRef = new Map<number, any>();
  private subphrases: Map<number, any> = new Map<number, any>();
  public _phrasesByFamily: Map<number, any[]> = new Map<number, any[]>();
  public _showTranslated: Map<number, boolean> = new Map<number, boolean>();
  public _showFamilyMatched: Map<number, boolean> = new Map<number, boolean>();
  public _showFamilyMismatched: Map<number, boolean> = new Map<number, boolean>();
  private _duplicatePhrases: number[] = [];
  private _icons: Map<number, string> = new Map<number, string>();
  private _badges: Map<number, string> = new Map<number, string>();
  private _annotations: Map<number, string> = new Map<number, string>();
  private _currentPatternId: number = 0;
  private _currentPatternFailures: Map<number, FailureReason[]>; // the key: pattern ID
  // the entities in focus
  public _entityGroups: Map<string, any> = new Map<string, any>();
  public _entityTypeDescriptions: Map<string, string[]> = new Map([
    ["lexical_item", ["📖 Lexical items", "definitions of lexical items, encountered during the analysis"]],
    ["sentence", ["📜 Sentences", "delimited by end sentence punctuation marks"]],
    ["family", ["👪 Families", "definitions of families possibly relevant to the session"]],
    ["word", ["🔠 Word candidates", "strings delimited by spaces - preliminary tokens before non-break rules and MWEs; not the lexical chunks"]],
    ["abuse", ["👺 Abuse", "instances of abuse detected in the content"]],
    ["sentiment", ["😐 Sentiment", "sentiment snippets detected in the content"]],
    ["entity", ["🖻 Entities", "entities detected in the content"]],
    ["generated", ["🏭 Generated nodes", "nodes generated over the translation"]],
    ["clitic", ["🦴 Clitics", "clitics detected in the content"]],
    ["segmentation", ["➗ Segmentations", "how the lexical constituents are divided"]],
    ["paragraph", ["📑 Paragraphs", "chunks of text delimited by line breaks"]],
    ["hypothesis", ["⚗️ Hypotheses", "interpretations of the lexical items"]],
    ["phrasal_element", ["🔣 Phrasal elements", "sets of conditions in a phrasal pattern"]],
    ["nonbreak_match", ["✨ Non-breaks", "Non-break rules that matched"]],
    ["input", ["🎙️ Input", "the original content"]],
    ["numeral", ["🔢 Numerals", "numeric constitutents"]],
    ["phoneme", ["💬 Phonemes", "encountered or invoked in the content"]],
    ["local_topic", ["📙 Topics at the sentence level", "domains of discourse encountered in the sentence"]],
    ["topic", ["📙 Topics", "domains of discourse at the level of the text"]],
    ["mwe", ["〰️ Multiword expressions", "Lexical chunks consisting of multiple words triggered during the analysis (but not necessarily detected)"]],
    ["punctuation_mark_definition", ["⁉️ Punctuation mark definitions", "definitions of punctuation marks, encountered during the analysis"]],
    ["constituent", ["🖹 Constituents", "lexical chunks, numerals, punctuation marks"]],
    ["phrasal_pattern", ["🔣 Phrasal patterns", "triggered during the analysis, whether they failed or succeeded"]],
    ["nondictionary_heuristic", ["👽 Nondictionary heuristics", "patterns triggered during the analysis"]],
    ["phrase", ["🗪 Phrases", "patterns that matched, fully or partially, during the analysis (but may still not make it to the end)"]],
    ["parse", ["🌴 Parse forests", "various combinations of phrases, organised as trees"]],
    ["commonsense_cue", ["🧠 Commonsense cues", "run during the analysis"]],
    ["commonsense_cue_match", ["🧠 Commonsense cue matches", "successfully detected during the analysis"]],
    ["memory", ["💾 Long-term Memory", "contents of long-term memory"]],
    ["", ["❓ Other entities", "unclassified entities. If you have something here, your LaMP may be out of date"]],
  ]);

  public _lexicalItems: { [key: string]: LexicalLinkHypotheses } = {};

  public _isHypothesis = {};
  public _nestedHypotheses: { [key: string]: LexicalLinkHypotheses } = {};
  public _nestedSenses: { [key: string]: LexicalLinkSenses[] } = {};

  public _localFilterState: { [key: string]: Map<number, Map<string, any[]>> } = {};
  public _panelHeaderStore = {};
  public _features: FeatureTypeList[];
  public _featureDescriptionStore = {};
  private _generalStore = {}; //prevent html reload due return new object
  public assumption: { [key: string]: string[] } = {};
  public warning: { [key: string]: string } = {};
  public showMatchedOnly = false;

  constructor(private ref: ChangeDetectorRef,
    private _language: LanguageService,
    public _notifier: MatSnackBar,
    protected translateService: TranslateService,
    protected localStorageHelper: LocalStorageHelper,
    protected sessionService: SessionService,
    private _sanitizer: DomSanitizer,
    private _phrasesService: PhrasesService,
    private _identityRolesService: IdentityRolesService) {
    super(translateService, localStorageHelper, sessionService);
  }

  ngAfterContentChecked(): void {
    this.ref.detectChanges();
  }

  ngOnInit(): void {
    this._stepDelay = 900;

  }

  ngOnDestroy(): void {
    this.reset();
    clearInterval(this._intervalTimer);
    this._intervalTimer = undefined;
    //throw new Error("Method not implemented.");
  }

  resetControl() {
    this._silent = false;
  }

  reset() {
    this.resetContent();
    this.resetControl();
  }

  resetContent() {
    this._phraseLevel = 0;
    this._currentLineIndex = 0;
    this._currentSection = "";
    this._previousSection = "";
    this._currentParse = undefined;
    this._sentence = "";
    this._sentences = undefined;
    this._sentiment = undefined;
    this._abuse = undefined;
    this._contentAfterHighlight = "";
    this._contentBeforeHighlight = "";
    this._contentInsideHighlight = "";
    this._controlOperationInProgress = false;
    this._currentLineIndex = 0;
    this._currentTitleParts = [];
    this._entityGroups.clear();
    this._entityGroupTypes = [];
    this._phrasesByFamily.clear();
    this._entitiesByRef.clear();
    this._isPlaying = false;
    this._operationOutcome = "";
    this._constituents = [];
    this._phrases = [];
    this._selectedNode = 0;
    this._highlighted = undefined;
    this._annotations.clear();
    this._badges.clear();
    this._icons.clear();
    this.assumption = {};
  }

  buttonBackQuickly() {
    //document.body.style.cursor = 'progress';
    this.silence();
    let targetLineIndex = this._currentLineIndex - this.SKIP_STEP_COUNT;
    if (targetLineIndex < 0)
      targetLineIndex = 0;
    this.resetContent();
    while (this.processNextLineSync() && this._currentLineIndex < targetLineIndex);
    //document.body.style.cursor = 'default';
    this.startAsyncProcessing();
  }

  buttonForwardQuickly() {
    //document.body.style.cursor = 'progress';
    this.silence();
    let targetLineIndex = this._currentLineIndex + this.SKIP_STEP_COUNT;
    while (this.processNextLineSync() && this._currentLineIndex < targetLineIndex);
    //document.body.style.cursor = 'default';
    this.startAsyncProcessing();
  }

  skipToLine(lineIndex: number) {
    this.silence();
    let targetLineIndex = lineIndex;
    if (targetLineIndex < this._currentLineIndex)
      this.resetContent();
    while (this.processNextLineSync() && this._currentLineIndex < targetLineIndex);
    this._controlOperationInProgress = false;
    //this.startAsyncProcessing();  
  }

  deleteEntity(runtimeId: number, reason: string) {
    if (!this._entitiesByRef.has(runtimeId)) return;
    let entity = this._entitiesByRef.get(runtimeId);
    if (!entity)
      return;
    if (reason && (reason.includes('duplicate found')
      || reason.includes('must have phrase elements beyond leaf level'))) {
      let phraseGroup = this.getFamilySubstore(this._phrasesByFamily, entity.family, 1)
      for (let pi = 0; pi < phraseGroup.length; pi++) {
        if (phraseGroup[pi].runtimeId === runtimeId) {
          phraseGroup.splice(pi, 1);
          break;
        }
      }
    }
    if (!entity.deleted)
      entity.deleted = reason ? reason : 'deleted';
  }

  finished(): boolean {
    return this._currentLineIndex >= this._lines.length;
  }

  disableControlButtons() {
  }

  enableControlButtons() {

  }

  buttonPreviousSection() {
    // basically, restart and show nothing until the section starts
    //document.body.style.cursor = 'progress';
    let previousBeforeWeChangeEverything = this._previousSection;
    this.silence();
    this.resetContent();
    while (this.processNextLineSync() && this._currentSection !== previousBeforeWeChangeEverything);
    //document.body.style.cursor = 'default';
    this.startAsyncProcessing();
  }

  buttonNextSection() {
    //document.body.style.cursor = 'progress';
    let sessionActiveWhenPressed = this._currentSection;
    this.silence();
    while (this.processNextLineSync() && this._currentSection === sessionActiveWhenPressed);
    //document.body.style.cursor = 'default';
    this.startAsyncProcessing();
  }

  buttonGoToStart() {
    this.resetContent();
    this.silence();
  }

  /***
   * Loads all entities and stops.
   */
  buttonGoToEnd() {
    this.silence();
    this._controlOperationInProgress = true;
    this._isPlaying = false;
    while (this.processNextLineSync());
    this._controlOperationInProgress = false;
  }

  startAsyncProcessing() {
    this._isPlaying = true;
    this._controlOperationInProgress = false;
    this._silent = false;
    this.updateProgress();
    this._intervalTimer = setInterval(this.processNextLine, this._stepDelay, this);
  }

  pauseProcessing() {
    clearInterval(this._intervalTimer);
    this._intervalTimer = undefined;
    this._isPlaying = false;
    this.issueMatchMessage("To resume, press Play");
  }

  buttonPlayPress(ev: Event) {
    if (!this._isPlaying) {
      // start or resume playing
      this.startAsyncProcessing();
    }
    else {
      // pause. Do NOT reset the line counter, but stop the timer / interval
      this.pauseProcessing();
    }
  }

  buttonStopPress() {
    //document.getElementById('ibutton_play').className = "fa fa-play";
    clearInterval(this._intervalTimer);
    this._intervalTimer = undefined;
    this.resetControl();
    this._currentLineIndex = 0;
  }
  /*
  processLog(ev: Event): void {
    var log = this._reader.result.toString();
    var lines = log.split('\n');
    for (let j = 0; j < lines.length; j++) {
        this.scheduleLineProcessing(j, lines[j]);
    }

  }
  */

  focusOnEntityByRef(ref: number): boolean {
    if (this._entitiesByRef.has(ref))
      return this.focusOnEntity(this._entitiesByRef.get(ref));
    else
      return false;
  }

  isParseTreeNode(obj): boolean {
    return obj && (obj.type === 'phrase' || obj.type === 'node' || obj.type === 'constituent'
      || obj.type === 'lexical_item');
  }

  sortPhraseArray(phraseArray: any[]): void {
    phraseArray.sort(function (phrase1, phrase2): number {
      if (!phrase1 || !phrase2)
        return 0;
      return phrase1.offset === phrase2.offset ? phrase1.length - phrase2.length
        : phrase1.offset - phrase2.offset;
    });
  }

  //763: Enhancements to debugger: display of hypotheses
  twoLastTitleParts(tabText: string): string {
    //simply return 'trigger mismatch' if not included 'Identity '
    const defaultSectionText = "trigger mismatch";
    if (!this._currentTitleParts || this._currentTitleParts.length === 0) {
      return defaultSectionText;
    }

    const lastPartText = this._currentTitleParts[this._currentTitleParts.length - 1];
    if (lastPartText.indexOf("agreement") >= 0) {
      return lastPartText;
    }

    const identityIndex = this._currentTitleParts.findIndex(x => x.indexOf("Identity ") >= 0);
    if (identityIndex == -1) {
      return defaultSectionText;
    }

    //remove emoji 🔎 if existed
    let displayMessage: string = this._currentTitleParts[identityIndex].replace('🔎', '');


    //get sequence text and remove emoji 🤔 if existed
    let nextPart = identityIndex <= this._currentTitleParts.length - 2 ? this._currentTitleParts[identityIndex + 1] : '';
    nextPart = nextPart.replace(' 🤔', '');

    if (nextPart && nextPart !== tabText) {
      displayMessage += ' > ' + nextPart;
    }

    if (displayMessage.length < 75)
      return displayMessage; // otherwise, it's too damn long
    else
      return displayMessage.substr(0, 72) + '...';
  }

  findFailures(runtimeId: number, phraseRuntimeId: number): FailureReason[] {
    let target = this.findFailureMap(runtimeId);
    if (!target.failures)
      return undefined;
    return target.failures.get(phraseRuntimeId);
  }

  findFailureMap(runtimeId: number): any {
    if (!this._entitiesByRef.has(runtimeId))
      return undefined;
    return this._entitiesByRef.get(runtimeId);
  }

  linkFailure(runtimeId, failureReasonMessage, linkedRuntimeId, lineNumber: number, isTarget2: boolean = false): void {
    let targetEntity;
    if (!this._entitiesByRef.has(runtimeId)) {
      targetEntity = { runtimeId: runtimeId, failures: new Map<number, FailureReason[]>(), type: undefined };
      this._entitiesByRef.set(runtimeId, targetEntity);
    } else
      targetEntity = this._entitiesByRef.get(runtimeId);

    let tabTextLevel1;
    if (isTarget2) {
      const linkTargetEntity = this._entitiesByRef.get(linkedRuntimeId);
      tabTextLevel1 = linkTargetEntity ? (linkTargetEntity.originalText || linkTargetEntity.text) : '';
    } else {
      tabTextLevel1 = targetEntity ? (targetEntity.originalText || targetEntity.text) : '';
    }

    let failureReason: FailureReason = {
      error: failureReasonMessage,
      lineIndex: lineNumber,
      breadcrumbs: this._currentTitleParts.join(' > '),
      section: this.twoLastTitleParts(tabTextLevel1),
      times: 1
    };

    if (targetEntity.parent) // this is for the elements
      targetEntity = targetEntity.parent;
    if (!targetEntity.failures)
      targetEntity.failures = new Map<number, FailureReason[]>();

    if (!targetEntity.lexicalItems)
      targetEntity.lexicalItems = [];

    if (!linkedRuntimeId)
      linkedRuntimeId = 0;

    //save is hypothesis for template checking
    const isHypothesis = this.isHypothese(linkedRuntimeId);
    this._isHypothesis[linkedRuntimeId] = isHypothesis;

    let targetList: FailureReason[];
    if (!targetEntity.failures.has(linkedRuntimeId))
      targetEntity.failures.set(linkedRuntimeId, [] as FailureReason[]);
    targetList = targetEntity.failures.get(linkedRuntimeId);
    for (let existingFailureReason of targetList)
      if (existingFailureReason.error === failureReason.error
        && existingFailureReason.breadcrumbs === failureReason.breadcrumbs) {
        existingFailureReason.times++;
        return;
      }
    targetList.unshift(failureReason);

    if (isHypothesis) {
      this.linkHypothesis(linkedRuntimeId, targetEntity, failureReason);
    }


  }

  /**
   * Find all hypotheses then linked it to lexical
   * @param linkedRuntimeId 
   * @param targetEntity 
   * @param failureReason 
   * @returns {LexicalLinkHypotheses} lexical item was linked to
   */
  private linkHypothesis(linkedRuntimeId: number, targetEntity: any, failureReason: FailureReason): LexicalLinkHypotheses {
    let lexemeId;
    const matchLexicalItem =
      Object.values(this._lexicalItems)
        .find(x => (x.hypotheses || [])
          .find(x => {
            lexemeId = x.lexemeId;
            return x.runtimeId === linkedRuntimeId
          }) != null);

    let index = targetEntity.lexicalItems.findIndex(x => x.runtimeId == matchLexicalItem.runtimeId);
    if (index < 0) {
      targetEntity.lexicalItems.push({
        runtimeId: matchLexicalItem.runtimeId,
        text: matchLexicalItem.text,
        hypotheses: []
      });
      index = targetEntity.lexicalItems.length - 1;
    }
    const allHypotheses = targetEntity.lexicalItems[index].hypotheses;
    const indexHypothese = allHypotheses.findIndex(x => x.runtimeId == linkedRuntimeId);

    if (indexHypothese < 0) {
      targetEntity.lexicalItems[index].hypotheses.push({
        runtimeId: linkedRuntimeId,
        lexemeId: lexemeId,
        failures: [failureReason]
      });
    } else {
      targetEntity.lexicalItems[index].hypotheses[indexHypothese].failures.push(failureReason);
    }
    return matchLexicalItem
  }

  isHypothese(runtimeId) {
    if (!runtimeId || runtimeId === 0)
      return false;
    let linkedEntity: any = this._entitiesByRef.get(runtimeId);
    if (!linkedEntity)
      return false;
    if (linkedEntity.parent)
      linkedEntity = linkedEntity.parent;

    if (!linkedEntity || linkedEntity.text)
      return false;
    else {
      if (linkedEntity.ref_lemma || linkedEntity.definition)
        return false;
      else {
        if (linkedEntity.name || linkedEntity.tag || linkedEntity.family || linkedEntity.id || linkedEntity.description)
          return false;
        if (linkedEntity.grammar)
          return true;
      }
    }
  }

  chipFaceFirsttoken(fullDescription: string): string {
    if (!fullDescription || fullDescription.indexOf(' ') < 0 || fullDescription.length < 20)
      return fullDescription;
    return fullDescription.substr(0, fullDescription.indexOf(' '));
  }


  chipFaceDescription(fullDescription: string): string {
    if (!fullDescription) return '';
    const MAX_LENGTH: number = 25;
    if (fullDescription.length > MAX_LENGTH)
      return fullDescription.substr(0, MAX_LENGTH) + '...';
    else
      return fullDescription;
  }

  focusOnEntity(obj): boolean {
    if (!obj || obj.type === "phoneme" || obj.type === "mwe") return false;
    if (obj.type === "parse") {
      if (this._currentParse === obj)
        return false; // do not update the screen
      this._currentParse = obj;
      return true;
    }

    if (obj.type === 'phrasal_pattern') {
      this._currentPatternFailures = new Map<number, FailureReason[]>();
      this._currentPatternId = obj.id;
    }

    for (let constituent of this._constituents) {
      if (constituent.runtimeId === obj.runtimeId) {
        if (this._selectedNode === obj.runtimeId)
          return false; // do not update the screen
        this._selectedNode = obj.runtimeId;
        return true;
      }
      if (constituent.segm) {
        for (let seg of constituent.segm)
          if (seg.items) {
            for (let li of seg.items)
              if (li.runtimeId === obj.runtimeId) {
                if (this._selectedNode === obj.runtimeId)
                  return false; // do not update the screen
                this._selectedNode = obj.runtimeId;
                return true;
              }
          }
      }
    }


    for (let lineNumber = 0; lineNumber < this._phrases.length; lineNumber++)
      for (let phrase of this._phrases[lineNumber])
        if (phrase.runtimeId && phrase.runtimeId === obj.runtimeId) {
          if (this._selectedNode === obj.runtimeId)
            return false; // do not update the screen
          this._selectedNode = obj.runtimeId;
          return true;
        }
    return false;
  }

  processNextLineSync(): boolean {
    if (this.processLogLineAndSkipIfNeeded(this) < this._lines.length)
      return true;
    else {
      this.beforeEndProcessing();
      this.endProcessing();
      return false;
    }
  }

  //maintain data after process all line: merge, delete, ...
  private beforeEndProcessing(): void {
    //1094: Add tabs under Commonsense Cue matches in Debugger
    const allCommonsenseCueMatches = this._entityGroups.get("commonsense_cue_match");
    this._entityGroups.set("commonsense_cue_match", this.groupCommonsenseCueMatch(allCommonsenseCueMatches));

    //merge hypothese to lexical that has error directly
    this._entityGroups.get("phrasal_pattern").forEach((phrases) => {
      phrases.forEach((phrase, phraseKey) => {

        //step 1: merge senses to another lexical failure item
        const step1MergeSense = () => {
          if (phrase.failures) {
            let removalList = [];
            phrase.failures.forEach((phraseFailure, failureKey) => {
              if (!this._isHypothesis[failureKey]) {
                for (const lexKey in phrase.lexicalItems) {
                  const lexicalItem = phrase.lexicalItems[lexKey];
                  let tabText = this.objectPanelHeaderSafeHTMLByHandle(failureKey);
                  tabText = this.removeEmoji(tabText);
                  if (lexicalItem.text == tabText) {
                    //merging lexical & hypothese to lexical
                    this._nestedHypotheses[phrase.runtimeId + '_' + failureKey] = lexicalItem.hypotheses;
                    delete phrase.lexicalItems[lexKey];
                  }
                }
                phrase.lexicalItems = phrase.lexicalItems.filter((x) => x);
              }

              const entity: TestResult = this._entitiesByRef.get(failureKey);
              if (entity && entity.type == EntityType.LEXICAL_ITEM) {
                entity.senses = entity.senses || [];
                entity.inactive = entity.inactive || [];
                entity.hypotheses = entity.hypotheses || [];
                const allSenseRuntimeIds = [];
                entity.hypotheses.concat(entity.inactive).forEach(hypothesis => {
                  const hypothesisRef = this._entitiesByRef.get(hypothesis.runtimeId);
                  if (hypothesisRef && hypothesisRef.senses.length > 0) {
                    hypothesisRef.senses.forEach(sense => allSenseRuntimeIds.push(sense.runtimeId))
                  }
                });
                phrase.failures.forEach((realFailure, realFailureKey) => {
                  if (allSenseRuntimeIds.find(x => x === realFailureKey)) {
                    removalList.push(realFailureKey);
                    this._nestedSenses[phrase.runtimeId + '_' + failureKey] = this._nestedSenses[phrase.runtimeId + '_' + failureKey] || [];
                    let index = this._nestedSenses[phrase.runtimeId + '_' + failureKey].findIndex(x => x.runtimeId == realFailureKey);
                    if (index == -1) {
                      this._nestedSenses[phrase.runtimeId + '_' + failureKey].push({
                        runtimeId: realFailureKey,
                        failures: realFailure
                      });
                    } else {
                      this._nestedSenses[phrase.runtimeId + '_' + failureKey][index].failures.concat(realFailure);
                    }
                  }
                });
              }
            });
            removalList.forEach(id => {
              phrase.failures.delete(id);
            })
          }
        }

        //step 2: merge senses to another lexical that grouped by hypotheses(it is not an lexical item under failures list)
        const step2MergeSense2 = () => {
          if (phrase.lexicalItems) {
            const removalList = [];
            for (const lexicalItem of phrase.lexicalItems) {
              phrase.failures.forEach((phraseFailure, failureKey) => {
                const entity = this._entitiesByRef.get(failureKey);
                if (entity && entity.type == EntityType.SENSE) {
                  //find lexical text of sense
                  this._entityGroups.get(EntityType.LEXICAL_ITEM).forEach(globalLexItem => {
                    globalLexItem.hypotheses = globalLexItem.hypotheses || [];
                    globalLexItem.hypotheses.find(hypothesis => {
                      hypothesis.senses = hypothesis.senses || [];
                      const matchedSense = hypothesis.senses.find(x => x.runtimeId == failureKey);
                      if (matchedSense && globalLexItem.tex === lexicalItem.textt) {
                        removalList.push(failureKey);
                        lexicalItem.senses = lexicalItem.senses || [];
                        lexicalItem.senses.push({
                          runtimeId: failureKey,
                          failures: phraseFailure
                        });
                      }
                    });
                  });
                }
              });
            }
            removalList.forEach(id => {
              phrase.failures.delete(id);
            });
          }
        }

        //step 3: remove duplicated tab hypotheses and their senses appeared under lexical together
        const step3RemoveDuplicateTab = () => {
          if (phrase.lexicalItems && phrase.lexicalItems.length > 0) {
            phrase.lexicalItems.forEach(lexicalItem => {
              lexicalItem.senses = lexicalItem.senses || [];
              lexicalItem.senses = lexicalItem.senses.filter(sense => {
                let isExisted;
                lexicalItem.hypotheses = lexicalItem.hypotheses || [];
                for (const hypothesis of lexicalItem.hypotheses) {
                  if (this.isSenseBelongsToHypo(sense.runtimeId, hypothesis.runtimeId)) {
                    isExisted = true;
                    break;
                  }
                }
                return !isExisted;
              });
            });
          }
        }

        phrase.failures = this.removeDuplicatedSenses(phrase.failures);

        //step 1: merge senses to another lexical failure item
        step1MergeSense();

        //step 2: merge senses to another lexical that grouped by hypotheses(it is not an lexical item under failures list)
        step2MergeSense2();

        //step 3: remove duplicated tab hypotheses and their senses appeared under lexical together
        step3RemoveDuplicateTab();
      });
    });
  }

  /**
   * Remove duplicated senses if existed
   * @param failures failures list
   * @returns new list after remove duplicated senses
   */
  private removeDuplicatedSenses(failures: Map<number, any>): Map<number, any> {
    if (!failures || failures.size === 0) {
      return failures;
    }

    const result: Map<number, any> = new Map<number, any>();
    failures.forEach((entity, key) => {
      const entityRef = this._entitiesByRef.get(key);
      if (!entityRef || entityRef.type !== EntityType.SENSE) {
        result.set(key, entity);
      } else if (entityRef.type == EntityType.SENSE) {
        let existedKey;
        result.forEach((value, key) => {
          const valueRef = this._entitiesByRef.get(key);
          if (valueRef && valueRef.family == entityRef.family && (!value.definition || entityRef.definition)) {
            existedKey = key;
          }
        });
        if (existedKey) {
          result.delete(existedKey);
        }
        result.set(key, entity);
      }
    });
    return result;
  }

  /**
   * Check whether a sense belong to a hypothesis
   * @param senseId 
   * @param hypothesisId 
   * @returns {boolean} true if it belong. Otherwise, return false
   */
  isSenseBelongsToHypo(senseId: number, hypothesisId: number): boolean {
    const senseEntity = this._entitiesByRef.get(senseId);
    const hypoEntity = this._entitiesByRef.get(hypothesisId);
    if (
      !senseEntity || senseEntity.type !== EntityType.SENSE ||
      !hypoEntity || hypoEntity.type !== EntityType.HYPOTHESIS
    ) {
      return false;
    }
    let isMatched = false;
    this._entityGroups
      .get(EntityType.LEXICAL_ITEM)
      .forEach((lexicalItem) => {
        const result = (lexicalItem.hypotheses || []).find(
          (hypothesesis) =>
            hypothesesis.runtimeId == hypothesisId &&
            (hypothesesis.senses || []).find((x) => x.runtimeId === senseId)
        );
        if (result) {
          isMatched = true;
          return;
        }
      });
    return isMatched;
  }

  issuePopupNotification(msg: string, action: string): void {
    if (this._notifier._openedSnackBarRef) {
      this._notifier._openedSnackBarRef.dismiss();
      //this._notifier._openedSnackBarRef = undefined;
    }
    if (this._silent)
      return;
    this._notifier.open(msg, action, {
      duration: 2000,
    });
  }

  issueMatchMessage(msg: string): void {
    this.issuePopupNotification(msg, '👍');
  }

  /***
   * Gets whether the current line does not update the screen and should not be processed.
   */
  mustSkip(obj): boolean {
    return (obj.debug); // || obj.section);
  }

  updateProgress() {
    if (!this._lines || !this._lines.length)
      this._progress = 0;
    else
      this._progress = this._currentLineIndex / this._lines.length * 100;
  }

  disableTimer(): void {
    clearInterval(this._intervalTimer);
    this._intervalTimer = undefined;
  }

  endProcessing() {
    this.disableTimer();
    this._selectedNode = 0;
    this._highlighted = undefined;
    this._annotations.clear();
    this._badges.clear();
    this._icons.clear();
    this._isPlaying = false;
    this._localFilterState = {
      [EntityType.COMMONSENSE_CUE]: _.cloneDeep(this._entityGroups.get(EntityType.COMMONSENSE_CUE)),
      [EntityType.PHRASAL_PATTERN]: _.cloneDeep(this._entityGroups.get(EntityType.PHRASAL_PATTERN))
    };

    //1267: rearrangement of accordion sections in the Debugger
    // Phrases and Phrasal patterns 
    if (this._entityGroupTypes.indexOf(EntityType.PHRASAL_PATTERN) > -1 && this._entityGroupTypes.indexOf(EntityType.PHRASE) > -1) {
      this._entityGroupTypes.splice(this._entityGroupTypes.indexOf(EntityType.PHRASAL_PATTERN), 1);
      this._entityGroupTypes.splice(this._entityGroupTypes.indexOf(EntityType.PHRASE) + 1, 0, EntityType.PHRASAL_PATTERN);
    }

    // Commonsense cue matches and Commonsense cues
    if (this._entityGroupTypes.indexOf(EntityType.COMMONSENSE_CUE) > -1 && this._entityGroupTypes.indexOf(EntityType.COMMONSENSE_CUE_MATCH) > -1) {
      this._entityGroupTypes.splice(this._entityGroupTypes.indexOf(EntityType.COMMONSENSE_CUE), 1);
      this._entityGroupTypes.splice(this._entityGroupTypes.indexOf(EntityType.COMMONSENSE_CUE_MATCH) + 1, 0, EntityType.COMMONSENSE_CUE);
    }

  }

  // JavaScript scope vagaries: https://stackoverflow.com/questions/5226285/settimeout-in-for-loop-does-not-print-consecutive-values
  processNextLine(self: VisualTracerComponent) {
    if (self.processLogLineAndSkipIfNeeded(self) >= self._lines.length) {
      self.endProcessing();
    }
    else {
      self.updateProgress();
    }
  }

  /*
  setProgressPosition() {
    let targetLineIndex = Math.round(this._progress / 100 * this._lines.length);
    if (targetLineIndex < 0)
      targetLineIndex = 0;
    if (targetLineIndex !== this._currentLineIndex) {
      this.pauseProcessing();
      if (targetLineIndex > this._currentLineIndex) {
        while(this.processNextLineSync() && this._currentLineIndex < targetLineIndex);
        this.startAsyncProcessing();  
      } else {
        this.resetContent();
        while(this.processNextLineSync() && this._currentLineIndex < targetLineIndex);
        this.startAsyncProcessing();  
      }
    }         
  }
  */

  isString(value) {
    return typeof value === 'string' || value instanceof String;
  }

  getIcon(obj): string {
    if (obj && obj.runtimeId && this._icons.has(obj.runtimeId))
      return this._icons.get(obj.runtimeId);
    else
      return "";
  }

  getBadge(obj): string {
    if (obj && obj.runtimeId && this._badges.has(obj.runtimeId))
      return this._badges.get(obj.runtimeId);
    else
      return "";
  }

  getAnnotation(obj): string {
    if (obj && obj.runtimeId && this._annotations.has(obj.runtimeId))
      return this._annotations.get(obj.runtimeId);
    else
      return "";
  }

  groupCommonsenseCueMatch(objects: TisaneEntity) {
    const groups = _.groupBy(objects, 'parse_id');
    return Object.keys(groups).map(parseId => ({
      key: parseId && +parseId ? `Parse forest ${parseId}` : 'Combined',
      value: groups[parseId]
    }))
  }

  phraseTooltip(phrase): string {
    return '[' + phrase.tag + '] Family#' + phrase.family + ' ID#' + phrase.id;
  }

  featuresToDescription(arr: any[], lexemeId?: number): string {
    let description: string = "";
    arr = arr.sort((a, b) => a.index - b.index);
    for (let f of arr) {
      if (f.value === "ALL") continue;
      if (description.length > 0)
        description += ',' + f.index + '=' + f.value;
      else
        description = f.index + '=' + f.value;
    }

    if (lexemeId) {
      return '<a href="/lexicon?key=id&value=' + lexemeId + '" target="_blank" title="View Lexeme"> [Lexeme#' + lexemeId + ']' + '</a>&nbsp;' + description;
    } else {
      return description;
    }
  }

  objectPrefix(obj): string {
    if (!obj)
      return undefined;
    let prefix: string = obj.best ? "🏆 " : "";
    if (obj.generation && (obj.type == 'phrase' || obj.type == 'lexical_item'))
      prefix += "<span title='translate/generation'>💬</span>&nbsp;";
    if (obj.incomplete)
      prefix += "😞 ";
    if (obj.deleted)
      prefix += "🗑️ ";
    if (obj.swappedInnerPhrase || obj.swappedOuterPhrase)
      prefix += "🔀 ";
    if (obj.type === 'sentiment' || obj.sentiment) {
      let polarity = obj.polarity ? obj.polarity : (obj.sentiment.polarity ? obj.sentiment.polarity : '');
      switch (polarity) {
        case 'positive': prefix += "🙂 ";
          break;
        case 'negative': prefix += "☹️ ";
          break;
      }
    }
    if (obj.type === 'abuse' || obj.abuse) {
      let abuse_type = obj.abuse && obj.abuse[0] && obj.abuse[0].type ? obj.abuse[0].type :
        (obj.abuse_type ? obj.abuse_type : '');
      switch (abuse_type) {
        case 'bigotry': prefix += "😡 ";
          break;
        case 'personal_attack': prefix += "🗡️ ";
          break;
        case 'criminal_activity': prefix += "🕵️ ";
          break;
        case 'profanity': prefix += "🤬 ";
          break;
        case 'data_leak': prefix += "🐱‍💻 ";
          break;
        case 'sexual_advances': prefix += "💋 ";
          break;
        case 'external_contact': prefix += "🤝 ";
          break;
        case 'spam': prefix += "🚯 ";
          break;
      }
    }
    return prefix;
  }

  objectDescription(obj: any): string {
    if (!obj)
      return undefined;

    let prefix: string = this.objectPrefix(obj);
    let postfix = (obj.leafOnly ? '🍀' : '') + (obj.locking ? '🔒' : '');

    if (obj.hypotheses) {
      postfix += obj.hypotheses.find(h => h.stopword) ? ' 🛑' : '';
    }

    if (obj.family && (obj.text || obj.definition)) {
      if (obj.type == EntityType.SENSE) {
        prefix += ' <a href="/knowledge-graph?key=id&value=' + obj.family + '" target="_blank" title="View family">[Family#' + obj.family + ']</a> ';
      } else {
        prefix += " Family#" + obj.family + " ";
      }
    }
    if (obj.id && (obj.text || obj.definition))
      prefix += " Id#" + obj.id + " ";

    if (obj.text)
      return prefix + obj.text + " " + postfix;
    else {
      if (obj.ref_lemma)
        return prefix + obj.ref_lemma + " " + postfix;
      if (obj.definition)
        return prefix + obj.definition + " " + postfix;
      else {
        let res: string = "";
        if (obj.name)
          res += obj.name;
        if (obj.tag)
          res += '[' + obj.tag + '] ';
        if (obj.family)
          res += 'Family#' + obj.family + ' ';
        if (obj.id)
          res += 'ID#' + obj.id;
        if (obj.description)
          res += ' ' + obj.description;
        if (res.length < 1) {
          if (obj.grammar)
            res += this.featuresToDescription(obj.grammar, obj.lexeme);
        }
        if (res.length < 1) {
          if (obj.normalized)
            res += obj.normalized;
          if (obj.identity)
            res += 'Identity ' + obj.identity + ' ';
          if (obj.conditions)
            res += this.generateDefinition(obj.conditions);
          if (obj.type == EntityType.NON_BREAK_MATCH && obj.trigger)
            res += '<a href="/non-breaks#' + obj.trigger + '" target="_blank" title="View Non-break">' + obj.trigger + '</a>';

        }
        res += " " + prefix;
        return res;
      }
    }
  }

  getConditionTextDecorationStyle(cnd): string {
    if (cnd.mode === '(fail if can be)')
      return 'double';
    else
      return '';
  }

  getConditionTextDecoration(cnd): string {
    if (cnd.mode === '(except)' || cnd.mode === '(fail if can be)')
      return 'line-through';
    else
      return '';
  }

  elementsOrConditions(obj) {
    if (obj.element_conditions) return obj.element_conditions;
    else return obj.elements;
  }

  allHypotheses(obj) {
    let hypotheses = [];
    if (obj.inactive)
      hypotheses = obj.hypotheses.concat(obj.inactive);
    else
      hypotheses = obj.hypotheses;

    // Highlight the interpretation of the selected lexical item
    if (obj.detectedSense && obj.detectedSense.family) {
      return [hypotheses.find(e => e.family == obj.detectedSense.family)].concat(hypotheses.filter(e => e.family !== obj.detectedSense.family))
    }
    return hypotheses;
  }

  objectPanelHeaderSafeHTMLPhr(obj): SafeHtml {
    return this.objectPanelHeaderSafeHTML(obj);
  }

  objectPanelHeaderSafeHTML(obj): SafeHtml {
    const header = this._sanitizer.bypassSecurityTrustHtml(this.objectPanelHeader(obj));
    this._panelHeaderStore[obj.runtimeId] = header;
    return header;
  }

  objectPanelHeaderSafeHTMLByHandle(runtimeId: number) {
    if (!runtimeId || runtimeId === 0)
      return 'Miscellaneous';
    let linkedEntity: any = this._entitiesByRef.get(runtimeId);
    if (!linkedEntity)
      return runtimeId.toString();
    if (linkedEntity.parent)
      linkedEntity = linkedEntity.parent;
    let description: string = this.objectDescription(linkedEntity);
    if (!description || description.trim().length < 1)
      description = '???';
    return this.getObjectEmoji(linkedEntity) + ' ' + description;
  }

  /**
   * Similar function `objectPanelHeaderSafeHTMLByHandle`
   * but it was used for new hypothesis under lexical item tab
   * or new sense was merged to lexical item tab
   * @param runtimeId hypothesis or sense runtimeId
   * @returns tab description
   */
  getHypothesisSenseDescription(runtimeId: number) {
    let linkedEntity: any = this._entitiesByRef.get(runtimeId || 0);
    if (!linkedEntity || !linkedEntity.family ||
      (linkedEntity.type != EntityType.HYPOTHESIS && linkedEntity.type != EntityType.SENSE))
      return this.objectPanelHeaderSafeHTMLByHandle(runtimeId);

    const families = this._entityGroups.get(EntityType.FAMILY);
    const linkedFamily = families && families.length > 0 ? families.find(x => x.family == linkedEntity.family) : {};
    if (linkedFamily) {
      return '👪 <a href="/knowledge-graph?key=id&value=' + linkedEntity.family + '" target="_blank" title="View family">[Family#' + linkedEntity.family + ']</a> ' + linkedFamily.definition;
    } else {
      return this.objectPanelHeaderSafeHTMLByHandle(runtimeId);
    }
  }

  objectPanelHeader(obj): string {
    if (!obj)
      return "";
    let res: string = "";

    //res += '<a href="#' + obj.runtimeId + '" id="a' + obj.runtimeId + '">' + obj.runtimeId + '</a>';
    if (obj.url) {
      res += '<a href="' + obj.url + '" target="_blank">' + this.objectDescription(obj) + '</a>';
    }
    else
      res += this.objectDescription(obj);

    return res;

  }

  generateParseTree(parentNode, nesting: number): string {
    if (nesting > 10)
      return "";
    let parentRuntimeId: number = parentNode.parent.ref;
    if (!this._entitiesByRef.has(parentRuntimeId)) return "";
    let html: string = "";
    let phrase = this._entitiesByRef.get(parentRuntimeId);
    if (!phrase || phrase.type !== "phrase") return "";
    html += '<span title="' + this.phraseTooltip(phrase) + '" style="font-size:large;border:solid 1px #000;background-color:'
      + (this._selectedNode === parentRuntimeId ? 'orange' : 'lightgray')
      + ';padding:5px;border-radius:5px 5px 5px 5px;-moz-border-radius:5px 5px 5px 5px;"><b>' +
      (phrase.text.includes("<b>" + phrase.tag) ? "" : phrase.tag) + "</b> "
      + phrase.text + "</span>";
    if (parentNode.children) {
      html += '<ul style="list-style: none; margin-top: 15px">';
      for (let child of parentNode.children)
        html += "<li>" + this.generateParseTree(child, nesting + 1) + "</li><li></li>";
      html += "</ul>";
    }
    return html;
  }

  outputParse(): SafeHtml {
    let html: string = '<span><p>🌴 ' + this.objectDescription(this._currentParse) + '</p>';
    for (let root of this._currentParse.phrases)
      html += this.generateParseTree(root, 0);
    html += '</span>';
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  /***
   * Looks for and returns a feature with index=1. If not found, undefined is returned.
   */
  getFeature1(grammar): string {
    if (!grammar) return undefined;
    for (let ft of grammar)
      if (ft.index === 1)
        return ft.value;
    return undefined;
  }

  findObject(runtimeId: number) {
    const BOUNDARY_PREFIX: string = '{"title"';
    const SECTION_PREFIX: string = '{"section"';
    const TYPE_FRAGMENT: string = '{"type":"';
    const MAX_MATCH_COUNT: number = 50;
    const MAX_DESCRIPTION_LENGTH: number = 45;
    this._searchMatches = [];
    this.pauseProcessing();
    this._searchOverflow = "";
    this._highlighted = this._entitiesByRef.get(runtimeId);
    for (let li = this._lines.length - 1; li > 50; li--) {
      let currentLine = this._lines[li];
      if (currentLine.includes(runtimeId.toString())) {
        // match! No matter where...
        let description = "";
        let rangeStartIndex = li;
        let briefDescription = "";
        let json = JSON.parse(currentLine);
        let type = json.type;
        if (li < this._lines.length - 1 && this._lines[li + 1].includes(TYPE_FRAGMENT + type))
          continue; // it's defined but not mentioned; next line defines another one of the same type 
        for (; rangeStartIndex > -1; rangeStartIndex--) {
          if (this._lines[rangeStartIndex].startsWith(BOUNDARY_PREFIX)) {
            description = JSON.parse(this._lines[rangeStartIndex]).title;
            if (description.length > MAX_DESCRIPTION_LENGTH)
              briefDescription = '...' + description.substring(description.length - MAX_DESCRIPTION_LENGTH);
            else
              briefDescription = description;
            break;
          }
        }
        let rangeEndIndex = li + 1;
        for (; rangeEndIndex < this._lines.length; rangeEndIndex++) {
          if (this._lines[rangeEndIndex].startsWith(BOUNDARY_PREFIX))
            break;
        }
        let rangeSpan = (rangeEndIndex - rangeStartIndex) * 100 / this._lines.length;
        let rangeStart = Math.floor(rangeStartIndex * 100 / this._lines.length);
        if (rangeStart > 0 || this._searchMatches.length < 1) {
          let section = "";
          for (let sli = li - 1; li > -1; li--) {
            // we can't spot the section name along the way because we're going backwards
            currentLine = this._lines[sli];
            if (currentLine.startsWith(SECTION_PREFIX)) {
              section = JSON.parse(currentLine).section;
              break;
            }
          }
          this._searchMatches.push({
            start: rangeStartIndex, span: rangeSpan,
            briefDescription: briefDescription, description: description, section: section
          });
          rangeStartIndex -= 100; // we don't need them next to each other
        }
        if (this._searchMatches.length > MAX_MATCH_COUNT) {
          this._searchOverflow = "(too many - only displaying the last " + MAX_MATCH_COUNT + ")";
          break;
        }
        li = rangeStartIndex;
      }
    }
    document.getElementById("beforeSearchMatches").scrollIntoView({ behavior: "smooth" });
  }

  generateFamilyLookupHTML(family: number): string {
    return '<a href="/knowledge-graph?key=id&value=' + family + '" target="_blank" title="View family">' + family + '</a>';
  }

  unsanitizeStyle(str: string): SafeStyle {
    return this._sanitizer.bypassSecurityTrustStyle(str);
  }

  objectToSafeHTML(obj) {
    if (!obj)
      return '';
    const key = circularJSON.stringify(obj);
    if (this._generalStore[key])
      return this._generalStore[key];

    if (obj.runtimeId == 1028) {
      debugger;
    }

    const style = `
      <style>
        summary {
          cursor: pointer;
        }
        summary {list-style: none}
        summary::-webkit-details-marker {display: none; }
        details > summary::before { 
          content: "➕ "; 
        }
        details[open] > summary::before { 
          content: "➖ "; 
        }
      </style>
      `;

    //delete temp value of analysis process
    obj = JSON.parse(circularJSON.stringify(obj));
    delete obj.lexicalItems;

    const result = this.objectToInnerHTML(obj, 0);
    this._generalStore[key] = this._sanitizer.bypassSecurityTrustHtml(style + result);
    return this._generalStore[key];
  }

  objectToInnerHTML(obj, nesting: number): string {
    if (nesting > 10)
      return "";

    let res: string = "";
    if (obj.runtimeId && this._entitiesByRef.has(obj.runtimeId))
      this.updateEntity(obj, this._entitiesByRef.get(obj.runtimeId));

    let deletePanel: string = '';
    if (obj.deleted) {
      deletePanel = '<span style="border-radius: 3px;padding: 3px;border-color:red;border-style:solid">🗑️&nbsp;' + obj.deleted + '</span>';
    }

    if (nesting < 1) {
      res = deletePanel + '<ul id="' + obj.runtimeId + '" style="list-style: none;">';
    } else
      res = deletePanel + '<ul style="list-style: none;">';

    for (var property in obj) {
      if (property === 'url' || property === 'element_conditions' || property === 'deleted'
        || property === 'linked' || property === 'colspan'
        || property === 'hypotheses' || property === 'failures'
        || property == 'lastFailureReason' || nesting < 1
        && (property === 'text' || property === 'definition'))
        continue;
      if (property === 'ref' || property === 'target_node' || property === 'target') {
        let runtimeId = obj[property];
        if (this._entitiesByRef.has(runtimeId)) {
          res += '<li>' + this.objectPanelHeader(this._entitiesByRef.get(runtimeId)) + '</li>';
          break;
        }
      }
      if (obj.hasOwnProperty(property) && obj[property]) {
        if (obj[property].index && obj[property].value || obj[property].feature) {
          res += '<li>';
          const featureProperty = obj[property];
          for (var subprop in featureProperty) {
            if (!(subprop === "index" || subprop === "value"))
              res += subprop + "=" + obj[property][subprop] + "  ";
          }
          if (obj[property].feature)
            res += obj[property].feature.index + "=" + obj[property].feature.value;
          else
            res += obj[property].index + "=" + obj[property].value;
        }
        else {
          if (typeof obj[property] == "object" && !(obj[property] instanceof Array)) {
            res += '<li class="caret"><strong>' + property + '</strong>';
            res += this.objectToInnerHTML(obj[property], nesting + 1);
          } else {
            res += '<li>';
            if (!this.isString(obj[property]) && obj[property] instanceof Array) {
              let currentArrayProperty = obj[property];
              res += `<details ${nesting} ${nesting > 0 && currentArrayProperty.length <= 1 ? 'open' : ''}><summary><span><strong>` + property + '</strong></span></summary><ul style="list-style: none;">';
              if (isNumber(currentArrayProperty[0]) || this.isString(currentArrayProperty[0])) {
                let generatePhraseUrl = false;
                if (property === 'required')
                  generatePhraseUrl = true;
                for (let ni = 0; ni < currentArrayProperty.length; ni++) {
                  let innerPart = currentArrayProperty[ni];
                  if (generatePhraseUrl)
                    innerPart = '<a href="/syntax-and-context/phrasal-patterns?key=id&value=' + innerPart + '" target="_blank" title="View phrase family">' + innerPart + '</a>';
                  res += '<li>' + innerPart + '</li>';
                }
              }
              else {
                if (currentArrayProperty[0]) {
                  if (currentArrayProperty[0].index && currentArrayProperty[0].value)
                    for (let fi = 0; fi < currentArrayProperty.length; fi++) {
                      let valueDump: string = currentArrayProperty[fi].index + '=' + currentArrayProperty[fi].value;
                      if (fi < 1)
                        res += valueDump;
                      else
                        res += ", " + valueDump;
                    }
                  else
                    for (let oi = 0; oi < currentArrayProperty.length; oi++) {
                      res += `<li>
                        <details ${nesting} ${nesting >= 0 && currentArrayProperty.length <= 1 ? 'open' : ''}>
                          <summary><span><strong>[` + oi + ']'
                        + '</strong></span></summary>&nbsp;' + this.objectPrefix(currentArrayProperty[oi])
                        + this.objectToInnerHTML(currentArrayProperty[oi], nesting + 1) + '</details></li>';
                    }
                }
              }
              res += '</ul></details>';
            } else {

              res += '<strong>' + property + '</strong>: ' + (property === 'family'
                ? this.generateFamilyLookupHTML(obj[property]) : obj[property]);
            }
          }
        }
        res += '</li>';
      }
    }
    res += '</ul>';

    return res;
  }

  endPosition(textChunk: any): number {
    return textChunk.colspan ? textChunk.offset + textChunk.colspan : textChunk.offset;
  }

  handleFileSelect(evt): void {
    if (this._isPlaying) {
      alert("Pause or stop before loading a new log");
      return;
    }
    this.resetContent();
    this.silence(true);
    let files = evt.target.files; // FileList object
    document.getElementById('btnPicklogLabel').innerText = evt.target.files[0].name;

    // Loop through the FileList and render image files as thumbnails.
    for (let i = 0, f; f = files[i]; i++) {

      let reader = new FileReader();

      //reader.addEventListener('load', this.processLog, false);

      // Closure to capture the file information.
      reader.onload = (e) => {
        //this.playLog(reader.result);
        let log = reader.result.toString();
        this._lines = log.split('\n');
      }

      reader.readAsText(f);
    }
  }

  distributeContentPieces(offset: number, len: number): boolean {
    let originalContent = this._contentBeforeHighlight + this._contentInsideHighlight + this._contentAfterHighlight;
    if (originalContent && offset > 0 && len > 0 && offset + len <= originalContent.length) {
      let part1: string = originalContent.substr(offset, len);
      let part2: string = originalContent.substr(offset + len);
      let part3: string = originalContent.substr(0, offset);
      if (this._contentInsideHighlight === part1 && this._contentAfterHighlight === part2
        && this._contentBeforeHighlight === part3)
        return false; // nothing to update
      this._contentInsideHighlight = part1;
      this._contentAfterHighlight = part2;
      this._contentBeforeHighlight = part3;
      return true;
    } else
      return false;
  }

  updateEntity(targetEntity: any, stored: any): void {
    if (!targetEntity.colspan && stored.colspan)
      targetEntity.colspan = stored.colspan;
    if (!targetEntity.score && stored.score)
      targetEntity.score = stored.score;
    if (!targetEntity.level && stored.level)
      targetEntity.level = stored.level;
    if (targetEntity.best == undefined && stored.best)
      targetEntity.best = stored.best;
    if (!targetEntity.deleted && stored.deleted)
      targetEntity.deleted = stored.deleted;
    if (!targetEntity.parent && stored.parent)
      targetEntity.parent = stored.parent;
    if (!targetEntity.failures && stored.failures) {
      targetEntity.failures = stored.failures;
    }
    if (!targetEntity.linked && stored.linked)
      targetEntity.linked = stored.linked;
    if (stored.elements) {
      if (targetEntity.elements) {
        for (let stel of stored.elements) {
          for (let tel of targetEntity.elements)
            if (tel.target_node === stel.target_node) {
              tel.senseTree = stel.senseTree;
              tel.mismatches = stel.mismatches;
              break;
            }
        }
      } else
        targetEntity.elements = stored.elements;
    }

  }

  mismatchSafeHTML(mismatch: FailureReason): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(`<a href="${mismatch.url}" target="_blank">${mismatch.section}</a>`);

  }

  sortedArray(orgMap) {
    return orgMap.keys();
    /*
    let arr = [...orgMap.entries()];
    return arr.sort();
    //return sortedMap.keys();
    */
  }

  objectKeys(obj) {
    return Object.keys(obj);
  }

  sortByProp(array: any[], prop: string, order: 'ASC' | 'DESC' = 'ASC') {
    array = array || [];
    return array.sort((a, b) => order == 'ASC' ? a[prop] - b[prop] : b[prop] - a[prop])
  }

  sortedByIndex(array: FeatureValue[]) {
    return array.sort((a, b) => a.index - b.index);
  }

  sortNumber(array: number[]) {
    return array.sort((a, b) => a - b);
  }

  mapKeys(objMap): IterableIterator<number> {
    return objMap.keys();
  }

  getObjectEmoji(obj: any): string {
    if (!obj.type || !this._entityTypeDescriptions.has(obj.type)) return '';
    let adjustedType: string = obj.type === 'sense' ? 'family' : obj.type;
    let emojiContainingDescription: string = this._entityTypeDescriptions.get(adjustedType)[0];
    let firstSpacePos: number = emojiContainingDescription.indexOf(' ');
    return firstSpacePos > 0 ? emojiContainingDescription.substring(0, firstSpacePos) : emojiContainingDescription;
  }

  countTranslated(phrases: any[]): number {
    let cnt: number = 0;
    for (let ph of phrases)
      if (ph.generation)
        cnt++;
    return cnt;
  }

  countSuccessfulMatches(phrases: any[]): number {
    let cnt: number = 0;
    for (let ph of phrases)
      if (!ph.incomplete)
        cnt++;
    return cnt;
  }

  showToggle(m: Map<number, boolean>, family: number): boolean {
    if (m.has(family))
      return m.get(family);
    else
      return false;
  }

  setToggle(m: Map<number, boolean>, family: number, $event) {
    m.set(family, $event.checked);
  }

  countFailedMatches(phrases: any[]): number {
    let cnt: number = 0;
    for (let ph of phrases)
      if (ph.incomplete)
        cnt++;
    return cnt;
  }

  countAliveMatches(phrases: any[]): number {
    let cnt: number = 0;
    for (let ph of phrases)
      if (!ph.deleted && !ph.incomplete)
        cnt++;
    return cnt;
  }

  /**
   * Gets a substore for the group of entities, CREATING IF NEEDED.
   * @param familyGrp the entity group
   * @param id the ID of the entity to store
   * @param groupFactor how many entities to store in one sub-store
   */
  getFamilySubstore(familyGrp, id: number, groupFactor: number): any {
    let familyCategory = Math.floor(id / groupFactor) * groupFactor;
    if (familyGrp.has(familyCategory)) return familyGrp.get(familyCategory);
    let newGrp = [];
    familyGrp.set(familyCategory, newGrp);
    return newGrp;
  }

  locationOf(element, array, start: number, end: number): number {
    start = start || 0;
    end = end || array.length;
    let pivot = start + (end - start) / 2;
    if (array[pivot] === element) return pivot;
    if (end - start <= 1)
      return array[pivot] > element ? pivot - 1 : pivot;
    if (array[pivot] < element) {
      return this.locationOf(element, array, pivot, end);
    } else {
      return this.locationOf(element, array, start, pivot);
    }
  }

  // either saves a new entity or updates an existing one
  storeEntity(json: any) {
    if (!json) return;
    let storedInMainMap: boolean = this._entitiesByRef.has(json.runtimeId);
    if (storedInMainMap) {
      let stored = this._entitiesByRef.get(json.runtimeId);
      let storedEntityGroup = this._entityGroups.get(stored.type);
      if (storedEntityGroup) {
        // sometimes, entities might be discarded and their IDs reused
        for (let i = 0; i < storedEntityGroup.length; i++)
          if (storedEntityGroup[i].runtimeId === stored.runtimeId) {
            storedEntityGroup.splice(i, 1);
            break;
          }
      }
      if ((!stored.type || json.type === stored.type) && (!stored.id || stored.id === json.id)) {
        this.updateEntity(json, stored);
      }
    } else {
      if (json.elements)
        for (let el of json.elements)
          el.parent = json;
    }

    this._entitiesByRef.set(json.runtimeId, json);

    if (json.type === 'family' || json.type === 'sense' && json.definition) {
      this._families.set(json.family, json);
    }

    let elementType = json.type;
    if (this._entityTypeDescriptions.has(elementType)) {
      //elementType = "";
      if (!this._entityGroups.has(elementType)) {
        if (json.type === 'commonsense_cue' || json.type === 'phrasal_pattern' || json.type === 'phrase')
          this._entityGroups.set(elementType, new Map<number, []>());
        else {
          this._entityGroups.set(elementType, []);
          switch (elementType) {
            case "topic": this._topics = this._entityGroups.get(elementType);
              break;
            case "sentiment": this._sentiment = this._entityGroups.get(elementType);
              break;
            case "abuse": this._abuse = this._entityGroups.get(elementType);
              break;
            case "sentence": this._sentences = this._entityGroups.get(elementType);
              break;
          }
        }
        if (elementType !== "hypothesis" && elementType !== "segmentation" && elementType !== "word"
          && elementType !== "phrasal_element")
          this._entityGroupTypes.unshift(elementType); // we want LIFO
      }
    }
    let entityGroup = this._entityGroups.get(elementType);
    if (entityGroup) {
      switch (json.type) {
        case 'commonsense_cue':
          entityGroup = this.getFamilySubstore(entityGroup, json.id, 100);
          break;
        case 'phrasal_pattern':
          entityGroup = this.getFamilySubstore(entityGroup, json.family, 50);
          break;
        case 'phrase':
          //case 'commonsense_cue_match':
          try {
            if (json.deleted && json.deleted.includes('duplicate found'))
              return;
            entityGroup = this.getFamilySubstore(this._phrasesByFamily, json.family, 1);
            if (!this._showFamilyMatched.has(json.family)) {
              this._showFamilyMatched.set(json.family, true);
              this._showFamilyMismatched.set(json.family, false);
            }

            if (json.generation) {
              this._showTranslated.set(json.family, true);
            }

            if (json.matches && !json.elements) {
              json.elements = json.matches;
              //console.info('aha!');
            }

            if (json.subphrases && json.subphrases.length > 0) {
              for (const sp of json.subphrases) {
                this.subphrases.set(sp.runtimeId, sp);
              }
            }

            for (let el of json.elements) {
              el.senseTree = this.generatePhraseMatchTree(el.matches);
              if (el.pattern && this._entitiesByRef.has(el.pattern)) {
                el.conditions = this._entitiesByRef.get(el.pattern).conditions;
              }
              if (el.mismatches && el.mismatches.length > 0)
                continue;
              // create a list of failures for the current element, 
              // reading failures associated with the current phrase pattern
              el.mismatches = [];
              this.addAgreementMismatches(el.pattern, el.mismatches, json.runtimeId);

              if (el.pattern && this._currentPatternFailures && this._currentPatternFailures.has(el.pattern)) {
                let failures: FailureReason[] = this._currentPatternFailures.get(el.pattern);
                for (let mismatch of failures) {
                  let alreadyThere: boolean = false;
                  for (let existingFailure of el.mismatches) {
                    if (existingFailure.linkedEntity === mismatch.linkedEntity
                      && existingFailure.error === mismatch.error) {
                      alreadyThere = true;
                      break;
                    }
                  }
                  if (alreadyThere)
                    continue;
                  if (this._entitiesByRef.has(mismatch.linkedEntity)) {
                    let e = this._entitiesByRef.get(mismatch.linkedEntity);
                    let id: string = e.lexeme ? e.lexeme : (e.family && e.id ? e.family + '/' + e.id
                      : (e.family ? e.family : e.id));
                    let label: string;
                    let tooltip: string;
                    if (e.grammar) {
                      let importantGrammar = this.getImportantGrammarValues(e.grammar).join(' ');
                      tooltip = '#' + id + ' ' + importantGrammar;
                      label = this.chipFaceFirsttoken(importantGrammar);
                    } else {
                      if (e.definition || e.family && this._families.has(e.family)) {
                        let familyDefinition = e.definition ? e.definition : this._families.get(e.family).definition;
                        label = this.chipFaceDescription(familyDefinition);
                        tooltip = '#' + e.family + ' ' + familyDefinition;
                      } else {
                        tooltip = this.objectDescription(e);
                        if (e.text) {
                          label = e.text;
                          if (label.includes('</b>'))
                            label = label.substr(label.indexOf('</b>') + 4);
                        } else {
                          if (e.description)
                            label = e.description;
                          else
                            label = this.chipFaceDescription(tooltip);
                        }
                      }

                    }
                    let mm: FailureReason = {
                      lineIndex: mismatch.lineIndex,
                      breadcrumbs: tooltip,
                      section: this.getObjectEmoji(e) + ' ' + label, times: 1,
                      url: e.url, error: mismatch.error, linkedEntity: mismatch.linkedEntity
                    };
                    for (let exMM of el.mismatches)
                      if (exMM.section === mm.section && exMM.error === mm.error) {
                        alreadyThere = true;
                      }
                    if (!alreadyThere)
                      el.mismatches.push(mm);
                  } else {
                    console.error("Not found: " + mismatch.linkedEntity);
                  }
                }
              }
            }
            this._currentPatternFailures = undefined;
            this._currentPatternId = undefined;
            for (let i = 0; i < entityGroup.length; i++) {
              if (entityGroup[i].runtimeId === json.runtimeId) {
                entityGroup[i] = json;
                return;
              }

              /**
               * Discussion: https://mantis.tisane.ai/view.php?id=802#c11692
               */
              // if (entityGroup[i].id === json.id 
              //   // && (entityGroup[i].incomplete && json.incomplete 
              //   //     || json.deleted && json.deleted.includes('beyond leaf level'))
              //   && entityGroup[i].elements.length === json.elements.length
              //   && json.text && json.text.length > 0
              //   && entityGroup[i].text === json.text)
              //   return; // redundant
            }
          } catch (e) {
            console.error(e);
            return;
          }
          break;
        case 'lexical_item':
          if (json.runtimeId) {
            this._lexicalItems[json.runtimeId] = {
              runtimeId: json.runtimeId,
              text: json.text,
              hypotheses: (json.hypotheses || []).map(x => {
                return { runtimeId: x.runtimeId, lexemeId: x.lexeme }
              })
            };
          }

      }
      //entityGroup.splice(this.locationOf(json, entityGroup, undefined, undefined) + 1, 0, json);
      entityGroup.push(json);
    }
  }

  markBest(runtimeId: number) {
    if (!this._entitiesByRef.has(runtimeId))
      return;

    let entity = this._entitiesByRef.get(runtimeId);
    entity.best = true;
    let group = this._entityGroups.get(entity.type);
    if (group && group.length > 1) {
      for (let e of group)
        if (e.runtimeId !== runtimeId)
          e.best = false;
    }
  }

  getSense(senseTree: TisanePhraseMainMatch[], family: number, definition: string, url: string): TisanePhraseMainMatch {
    for (let sense of senseTree)
      if (sense.id === family)
        return sense;

    if (!definition) {
      if (this._families.has(family)) {
        let fdef = this._families.get(family);
        definition = fdef.definition;
        url = fdef.url;
      }
      if (!definition)
        definition = 'Family#' + family;
    }

    let newSense: TisanePhraseMainMatch = {
      type: 'family', id: family, definition: definition, url: url,
      hypotheses: []
    };
    senseTree.push(newSense);
    return newSense;
  }

  getImportantGrammarValues(grammar: FeatureValue[]): string[] {
    let importantValues: string[] = [];
    if (!grammar)
      return importantValues;
    for (let f of grammar)
      if (!(f.value === 'ALL' || f.value === 'AFT' || f.value === 'BEF' || f.value === 'NA'
        || f.value === 'INC' || f.value === 'YES' || f.value === 'NO' || f.value === 'ACTT'
        || f.value === 'ACT' || importantValues.indexOf(f.value) > -1
      )) {
        if (isNaN(+f.value) && f.value != 'REG')
          importantValues.push(f.value);
        else
          importantValues.push(f.index.toString() + '=' + f.value);
      }
    return importantValues;
  }

  generatePhraseMatchTree(matches: any[]): TisanePhraseMainMatch[] {
    let senseTree: TisanePhraseMainMatch[] = [];
    for (let m of matches) {
      try {
        if (m.family && m.definition && m.url) {
          this.getSense(senseTree, m.family, m.definition, m.url);
          continue;
        }
        if (!this._entitiesByRef.has(m.ref)) {
          continue;
        }
        let entity = this._entitiesByRef.get(m.ref);
        if (entity.type === 'constituent') {
          // numeral
          senseTree.push({ type: 'numeral', id: entity.family, definition: entity.text, url: undefined });
          continue;
        }
        if (entity.type === 'sense' || entity.type === 'family') {
          this.getSense(senseTree, entity.family, entity.definition, entity.url);
          continue;
        }
        if (entity.type === 'phrase') {
          let pointBreak: number = entity.text.indexOf('</b>');
          senseTree.push({
            type: entity.type, id: entity.id,
            definition: pointBreak > 0 ? entity.text.substring(pointBreak + 4).trim() : entity.text, url: entity.url
          });
          continue;
        }
        if (entity.behavior) {
          senseTree.push({ type: 'punctuation', id: entity.id, definition: entity.text, url: entity.url });
          continue;
        }
        if (entity.type === 'hypothesis' && entity.senses) {
          for (let sns of entity.senses) {
            let targetSense = this.getSense(senseTree, sns.family, sns.definition, sns.url);
            let targetHypothesis: TisaneHypothesis;
            for (let hyp of targetSense.hypotheses) {
              if (hyp.runtimeId === m.ref) {
                targetHypothesis = hyp;
                break;
              }
            }
            if (!targetHypothesis) {
              targetHypothesis = {
                lexemeId: entity.lexeme, url: entity.url, runtimeId: entity.runtimeId,
                featureCodes: this.getImportantGrammarValues(entity.grammar), featureDescription: ''
              };
            }
            targetSense.hypotheses.push(targetHypothesis);
          }
        }
      } catch (es) {
        console.error(es);
      }
    }
    for (let sns of senseTree) {
      try {
        if (!sns.hypotheses || sns.hypotheses.length < 1) continue;
        if (sns.hypotheses.length === 1) {
          sns.hypotheses[0].featureDescription = sns.hypotheses[0].featureCodes.join(' ');
          continue;
        }

        for (let hyp of sns.hypotheses) {
          for (let fc of hyp.featureCodes) {
            let existsEverywhere: boolean = true;
            for (let another of sns.hypotheses) {
              if (another.runtimeId === hyp.runtimeId) continue;
              if (!another.featureCodes.find(element => element === fc)) {
                existsEverywhere = false;
                break;
              }
            }
            if (!existsEverywhere) {
              hyp.featureDescription = hyp.featureDescription + ' ' + fc;
            }

          }
        }
      } catch (e) {
        console.error(e);
      }
    }
    return senseTree;
  }

  generateDefinitionFromConditions(conditions: any[], allModes: boolean): string {
    let definition: string;
    for (let el of conditions) {
      if (el.mode && !allModes) continue;
      let clause: string = (el.mode ? el.mode + ' ' : '') + el.tag + ' '
        + (el.feature ? el.feature.index + '=' + el.feature.value : '')
        + (el.id ? el.id : '');
      if (definition)
        definition += ', ' + clause;
      else
        definition = clause;
    }
    return definition;

  }

  generateDefinition(conditions: any[]): string {
    let definition: string = this.generateDefinitionFromConditions(conditions, false);
    if (!definition)
      definition = this.generateDefinitionFromConditions(conditions, true);
    return definition;
  }

  processEntity(json: any, nesting: number): void {
    if (nesting > 8)
      return;
    this.storeEntity(json);
    if (json.segm) {
      for (let segmentation of json.segm)
        if (segmentation.type && segmentation.runtimeId)
          this.processEntity(segmentation, nesting + 1);
    }
    if (json.items) {
      for (let item of json.items)
        if (item.type && item.runtimeId)
          this.processEntity(item, nesting + 1);
    }
    if (json.hypotheses) {
      for (let hyp of json.hypotheses)
        if (hyp.runtimeId) {
          hyp.type = 'hypothesis';
          this.processEntity(hyp, nesting + 1);
        }
    }
    if (json.element_conditions) {
      for (let ec of json.element_conditions)
        if (ec.runtimeId) {
          ec.type = 'phrasal_element';
          ec.parent = json;
          ec.definition = this.generateDefinition(ec.conditions);
          this.processEntity(ec, nesting + 1);
        }
    }
    if (json.senses) {
      for (let sense of json.senses)
        if (sense.type && sense.runtimeId)
          this.processEntity(sense, nesting + 1);

    }

    if (json.subphrases) {
      for (let sp of json.subphrases)
        if (sp.type && sp.runtimeId)
          this.processEntity(sp, nesting + 1);

    }
  }

  silence(leaveControlButtonsEnabled?: boolean): void {
    if (this._notifier && this._notifier._openedSnackBarRef) {
      this._notifier._openedSnackBarRef.dismiss();
    }
    if (!leaveControlButtonsEnabled) {
      this._controlOperationInProgress = true;
    }
    clearInterval(this._intervalTimer);
    this._intervalTimer = undefined;
    this._silent = true;
    this.clearNotifications();
  }

  calculateRowSpan(constituent: any): number {
    return constituent.segm ? this._maxSegmentationCount - constituent.segm.length + 1 : 1;
  }

  calculatePhraseColspan(elementArray: any[]): number {
    let colspan: number = 0;
    for (let element of elementArray) {
      let entity = this._entitiesByRef.get(element.target_node);
      if (entity) {
        //if (entity.type === "phrase")
        colspan += entity.colspan ? entity.colspan : (entity.text ? entity.text.length : 0);
      }
    }
    return colspan;
  }

  clearNotifications(): void {
    this._icons.clear();
    this._annotations.clear();
    this._badges.clear();
  }

  clearText(): void {
    this._contentBeforeHighlight = "";
    this._contentInsideHighlight = "";
    this._contentAfterHighlight = "";
  }

  getPreviousNode(nodeId: number, nesting: number): any {
    if (nesting > 7 || !(nodeId > 0) || !this._entitiesByRef.has(nodeId))
      return this._constituents[0];
    let node = this._entitiesByRef.get(nodeId);
    if (node && node.type === "phrase")
      return this.getPreviousNode(node.elements[0].target_node, nesting + 1);
    let best = this._constituents[0];
    for (let constituent of this._constituents) {
      if (!constituent.colspan) continue;
      let end = constituent.offset + constituent.text.length;
      if (end <= node.offset && end > best.offset + best.text.length)
        best = constituent;
    }
    return best;
  }

  collectHypothesisLinked(hypothesis: any): number[] {
    let combined: number[] = [];
    if (hypothesis.linked)
      combined.push(...hypothesis.linked);
    else
      if (this._entitiesByRef.has(hypothesis.runtimeId)) {
        let hs = this._entitiesByRef.get(hypothesis.runtimeId);
        if (hs.linked)
          combined.push(...hs.linked);
      }
    if (hypothesis.senses) {
      for (let sns of hypothesis.senses) {
        if (sns.linked)
          combined.push(...sns.linked);
        else {
          if (this._entitiesByRef.has(sns.runtimeId)) {
            let ss = this._entitiesByRef.get(sns.runtimeId);
            if (ss.linked)
              combined.push(...ss.linked);
          }

        }
      }
    }
    if (combined.length < 1)
      return undefined;
    return combined;
  }

  linkEntities(entityToLinkToId: number, linkedId: number): void {
    if (!this._entitiesByRef.has(entityToLinkToId)) return;
    let entityToLinkTo: any = this._entitiesByRef.get(entityToLinkToId);

    if (!entityToLinkTo.linked) {
      entityToLinkTo.linked = [linkedId];
    } else {
      for (let lnkd of entityToLinkTo.linked) {
        if (lnkd === linkedId) {
          return; // already there
        }
      }

      entityToLinkTo.linked.push(linkedId);
    }
  }

  addPhraseToCurrentLevel(currentPhraseLevel: any[], phrase): void {
    if (currentPhraseLevel.length > 0) {
      let last = currentPhraseLevel[currentPhraseLevel.length - 1];
      if (this.endPosition(last) < phrase.offset)
        // create a filler
        currentPhraseLevel.push({
          offset: last.offset + last.colspan,
          runtimeId: 0, colspan: phrase.offset - last.colspan, length: phrase.offset - last.colspan, text: ''
        });
    }
    currentPhraseLevel.push(phrase);
  }

  processLogLineAndSkipIfNeeded(self: VisualTracerComponent): number {
    let lineBeingProcessed = self._lines[self._currentLineIndex];
    if (!lineBeingProcessed || lineBeingProcessed.trim().length < 1)
      return;

    //alert(line);
    let i: number = 0;
    const MAX_ITERATION_COUNT: number = 500;
    while (!self.parseLogLine(self, lineBeingProcessed) && self._currentLineIndex < MAX_ITERATION_COUNT) {
      lineBeingProcessed = self._lines[++self._currentLineIndex];
    }
    return ++self._currentLineIndex;
  }


  parseLogLine(self: VisualTracerComponent, lineBeingProcessed: string): boolean {
    const OPERATION_SUCCESS: string = 'done';
    const OPERATION_FAILURE: string = 'clear';
    try {
      let json = JSON.parse(lineBeingProcessed);
      while (self.mustSkip(json) && self._currentLineIndex < self._lines.length) {
        json = JSON.parse(self._lines[++this._currentLineIndex]);
      }

      self._selectionColor = 'orange';

      if (json.assumption) {
        this.assumption[json.type] = this.assumption[json.type] || [];
        this.assumption[json.type].push(json.assumption);
      }

      if (json.ref) {
        if (json.linked_ref) {
          self.linkEntities(json.ref, json.linked_ref);
          self.linkEntities(json.linked_ref, json.ref);
          return false;
        }
        if (self.focusOnEntityByRef(json.ref))
          return !self._silent;
        else
          return false;
      }
      if (json.section) {
        self._previousSection = self._currentSection;
        self._currentSection = json.section;
        return false;
      }
      if (json.language && !json.type) {
        if (json.lampId)
          self._language.setLanguage(json.lampId);
        return false;
      }
      if (json.best) {
        self.markBest(json.best);
        return true;
      }

      let screenChange: boolean = false;

      if (json.runtimeId && json.type) {
        self.processEntity(json, 0);
        if (self.focusOnEntity(json))
          screenChange = true;
      }

      if (json.delete) {
        self.deleteEntity(json.delete, json.reason);
        return false;
      }

      if (json.sentence && json.sentence.length) {
        self._sentence = json.sentence;
        self._totalSentenceSpan = self._sentence.length;
        return true;
      }

      if (json.convert && json.to && self._entitiesByRef.has(json.to)) {
        let entityTarget = self._entitiesByRef.get(json.to);
        self._entitiesByRef.set(json.convert, entityTarget);
        return false;
      }

      if (json.title) {
        self._currentTitleParts = json.title.split(' > ').filter(Boolean);
        if (self._currentTitleParts.length > 0 && self._currentTitleParts[self._currentTitleParts.length - 1].includes('] Family ')) // phrase matching
        {
          self.clearNotifications();
          screenChange = true;
        }
      }
      //document.getElementById('currentTitle').innerText = json.title;
      if (json.text && !(json.id || json.runtimeId)) {
        if (!self._input)
          self._input = json.text;
        self._text = json.text;
        self._contentBeforeHighlight = json.text;
        self._contentInsideHighlight = "";
        self._contentAfterHighlight = "";
      }

      // DEPERECATED: delete entity if found a refresh json
      if (json.refresh && self._entitiesByRef.has(json.refresh)) {
        let entity = self._entitiesByRef.get(json.refresh);
        if (entity) {
          self._selectedNode = json.refresh;
          // self._selectionColor = 'red';
          // #1365: Link from phrase to parse forest not shown in Debugger

          // self._entitiesByRef.delete(parseInt(json.refresh));
          // let group = self._entityGroups.get(entity.type);
          // if (group)
          //   for (let i = 0; i < group.length; i++)
          //     if (group[i].runtimeId === json.refresh) {
          //       group.splice(i, 1);
          //       break;
          //     }
        }
      }


      if (json.target && json.target === 'main') {
        screenChange = true;
        if (json.constituents) {
          if (json.constituents.length < 1)
            return false;
          self.clearText();
          const MIN_SPAN = 5;
          const MAX_SPAN = 20;
          const ICON_WIDTH = 4;
          let constituentArray: any[] = json.constituents as any[];
          this._maxSegmentationCount = 1;
          this._constituents = [];
          this._totalSentenceSpan = 0;
          if (this._entityGroups.has("constituent"))
            this._entityGroups.set("constituent", []);
          for (let i = constituentArray.length - 1; i > -1; i--) {
            let nextOffset = i === constituentArray.length - 1 ? 0 : constituentArray[i + 1].offset;
            let elementLength = constituentArray[i].text.length;
            constituentArray[i].colspan = elementLength + ICON_WIDTH;
            if (i < constituentArray.length - 1 && constituentArray[i + 1] && constituentArray[i + 1].offset)
              constituentArray[i].colspan += (constituentArray[i + 1].offset - elementLength
                - constituentArray[i].offset);
            if (constituentArray[i].colspan < MIN_SPAN)
              constituentArray[i].colspan = MIN_SPAN;
            if (constituentArray[i].colspan > MAX_SPAN)
              constituentArray[i].colspan = MAX_SPAN;
            if (constituentArray[i].segm && constituentArray[i].segm.length === 1
              && constituentArray[i].segm[0].items
              && constituentArray[i].segm[0].items.length === 1)
              constituentArray[i].segm[0].items[0].colspan = constituentArray[i].colspan;
            //constituentArray[i].tableOffset = this._totalSentenceSpan; 
            this._totalSentenceSpan += constituentArray[i].colspan;
          }
          let acc_colspan = 0;
          for (const cnst of constituentArray) {
            cnst.tableOffset = acc_colspan;
            acc_colspan += cnst.colspan;
            this.processEntity(cnst, 0);
            if (cnst.segm && cnst.segm.length > this._maxSegmentationCount)
              this._maxSegmentationCount = cnst.segm.length;
            this._constituents.push(cnst);
          }
        }
        else {
          if (json.phrases && json.phrases.length > 0) {
            screenChange = true;
            self._phraseLevel++;
            self.clearText();
            let startLevel = this._phrases.length;
            let currentLevel = startLevel;
            this.sortPhraseArray(json.phrases);
            if (json.phrases[0].offset > 0) {
              //TODO: get offset for the colspan from the 1st element in the 1st phrase
              let levelStartPhrase = { offset: 0, runtimeId: 0, colspan: json.phrases[0].offset, text: '' };
              this._phrases.push([levelStartPhrase]);
            }
            else
              this._phrases.push([]);

            currentLevel = this._phrases.length - 1;
            for (let ci = 0; ci < json.phrases.length; ci++) {
              let phrase = json.phrases[ci];
              if (phrase.offset > 0) {
                let previousNode = this.getPreviousNode(phrase.elements[0].target_node, 0);
                if (previousNode && previousNode.colspan)
                  phrase.offset = (previousNode.tableOffset ? previousNode.tableOffset : previousNode.offset)
                    + previousNode.colspan;
              }
              phrase.colspan = this.calculatePhraseColspan(phrase.elements);
              phrase.level = self._phraseLevel;
              phrase.originalText = phrase.text;
              phrase.text = '<b>' + phrase.tag + '&nbsp;</b> ' + phrase.text;
              this.storeEntity(phrase);
              if (ci < 1 || this._phrases[currentLevel] && this._phrases[currentLevel].length < 1
                || this.endPosition(json.phrases[ci - 1]) <= phrase.offset) {
                this.addPhraseToCurrentLevel(this._phrases[currentLevel], phrase);
              } else {
                let integrated: boolean = false;
                for (let li = startLevel; li <= currentLevel; li++) {
                  let currentLevelArray = this._phrases[li];
                  if (currentLevelArray.length === 0 || this.endPosition(currentLevelArray[currentLevelArray.length - 1]) <= phrase.offset) {
                    currentLevelArray.push(phrase);
                    integrated = true;
                    break;
                  }
                }
                if (!integrated) {
                  if (phrase.offset > 0) {
                    let filler = { offset: 0, runtimeId: 0, length: phrase.offset, colspan: phrase.offset, text: '' };
                    this._phrases[++currentLevel] = [filler, phrase];
                  }
                  else
                    this._phrases[++currentLevel] = [phrase];
                }

              }
            }

          }
        }
      }

      if (json.t && json.t === "found") {
        if (self._constituents && self._constituents.length < 1)
          screenChange = self.distributeContentPieces(json.offset, json.length);
        if (json.major) {
          self.issueMatchMessage(self._contentInsideHighlight);
          screenChange = true;
        }
      }
      if (json.success != undefined) {
        screenChange = true;
        self._operationOutcome = json.success ? OPERATION_SUCCESS : OPERATION_FAILURE;
        self._outcomeReason = "";

        let noPatternId = !(json.identity > 0) && json.phrase;

        if (noPatternId || (self._currentPatternId > 0 && !json.success)) {
          // for the phrases, where we can't rely on the phrase runtimeId and have to reassign it
          let reason: FailureReason = { lineIndex: self._currentLineIndex, linkedEntity: json.target, error: json.reason, breadcrumbs: json.title, times: 1 };
          if (noPatternId) {
            reason.linkedEntity2 = json.target2;
            reason.phrase = json.phrase;
          }
          self._currentPatternFailures = self._currentPatternFailures || new Map<number, FailureReason[]>();

          const patternId = noPatternId ? 0 : json.target2;
          if (self._currentPatternFailures.has(patternId))
            self._currentPatternFailures.get(patternId).push(reason);
          else {
            self._currentPatternFailures.set(patternId, [reason]);
          }
        }
        if (self._selectedNode > 0) {
          self._icons.set(self._selectedNode, self._operationOutcome);
          if (json.success) {
            self._annotations.set(self._selectedNode, "");
            if (json.identity > 0)
              self._badges.set(self._selectedNode, json.identity.toString());
          }
        }
        if (json.reason) {
          screenChange = true;
          self._outcomeReason = json.reason;
          if (self._selectedNode > 0) {
            self._icons.set(self._selectedNode, OPERATION_FAILURE);
            self._annotations.set(self._selectedNode, json.reason);
          }
          if (json.target && !json.success) {
            self.linkFailure(json.target, json.reason, json.target2, self._currentLineIndex);
            if (json.target2)
              self.linkFailure(json.target2, json.reason, json.target, self._currentLineIndex, true);
          }
          if (json.major) {
            let notification = json.reason;
            if (self._currentTitleParts.length > 0)
              notification = self._currentTitleParts[self._currentTitleParts.length - 1] + ':  ' + notification;
            self.issuePopupNotification(notification, "👎");
          }
        }
        else {
          if (json.success && json.major && self._currentTitleParts.length > 0) {
            self.issueMatchMessage(self._currentTitleParts[self._currentTitleParts.length - 1]);
            screenChange = true;
          }
        }
      } else {
        if (self._operationOutcome && self._operationOutcome !== '') {
          self._operationOutcome = '';
          screenChange = true;
        }
      }

      if (json.focus_offset && self._constituents.length < 1) {
        screenChange = self.distributeContentPieces(json.focus_offset, json.focus_len);
      }

      if (json.warning && json.type) {
        this.warning[json.type] = this.warning[json.type] ? `${this.warning[json.type]}\n\n\n${json.warning}` : json.warning;
      }

      if (self._silent)
        return false; // we only need the entities

      if (screenChange)
        self.ref.markForCheck();
      return screenChange;
    } catch (error) {
      console.error("Error processing log line [" + lineBeingProcessed + "]: " + error);
      return false;
    }
  }

  private removeEmoji(text: string): string {
    if (!text)
      return '';
    return text.replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '').trim();
  }

  public truncate(str: string, maxLength: number = 80): string {
    if (!str || str.length < maxLength) {
      return str;
    }

    if (str.length > maxLength) {
      return str.substr(0, 80) + '...';
    }
  }

  public localFilter(searchStr: string, group: EntityType) {
    searchStr = searchStr ? searchStr.toLowerCase().trim() : '';
    this._localFilterState[group] = _.cloneDeep(this._entityGroups.get(group));

    if (!searchStr) {
      return;
    }

    this._localFilterState[group].clear();
    this._entityGroups.get(group).forEach((value, key) => {
      const matchedValues = value.filter(e => {
        if (group == EntityType.COMMONSENSE_CUE) {
          const commonCueSenseId = e.id ? e.id.toString() : '';
          const commonCueSenseDesc = e.description ? e.description.toLowerCase() : '';
          return commonCueSenseId.indexOf(searchStr) > -1 || commonCueSenseDesc.indexOf(searchStr) > -1
        }

        if (group == EntityType.PHRASAL_PATTERN) {
          const phraseId = e.id ? e.id.toString() : '';
          const familyId = e.family ? e.family.toString() : '';
          return phraseId.indexOf(searchStr) > -1 || familyId.indexOf(searchStr) > -1
        }

        return false;
      })
      if (matchedValues.length > 0) {
        this._localFilterState[group].set(key, matchedValues);
      }
    })
  }

  getParseType(linkIds: any[]): any[] {
    return linkIds.filter(linkedId => {
      const entity = this._entitiesByRef.get(linkedId);
      return entity && entity.type == EntityType.PARSE;
    });
  }

  getValueByProperty(runtimeId: number, prop: string) {
    const entity = this._entitiesByRef.get(runtimeId);
    return entity && entity[prop] ? entity[prop] : '';
  }

  openParseForest(runtimeId: number) {
    const entity = this._entitiesByRef.get(runtimeId);
    if (entity) {
      const panelId = `entityType_${entity.type}`;
      const parent = document.getElementById(panelId).firstChild as HTMLElement;
      parent.click();
      setTimeout(() => {
        const child = document.getElementById(runtimeId.toString()).getElementsByTagName('mat-expansion-panel-header')[0] as HTMLElement;
        child.click();
      }, 500);
    }
  }

  getShortDescription(obj: any): string {
    let res: string = obj.text;
    if (res)
      return res;
    if (obj.grammar) {
      let grammar = this.getImportantGrammarValues(obj.grammar);
      if (grammar)
        return grammar.join(' ');
    }
    if (obj.lexeme)
      return "Lexeme#" + obj.lexeme;
    return obj;
  }

  /**
   * Adds mismatches saved under "pattern 0". 
   * @param pattern 
   * @param targetMismatchList 
   * @param phrase
   */
  addAgreementMismatches(pattern: number, targetMismatchList: any[], phrase: number) {
    if (this._currentPatternFailures && this._currentPatternFailures.has(0)) {
      const allPatternFailures = this._currentPatternFailures.get(0);
      for (const mismatch of allPatternFailures) {
        if (mismatch.linkedEntity2 === pattern && mismatch.phrase === phrase
          && this._entitiesByRef.has(mismatch.linkedEntity)) {
          let o = this._entitiesByRef.get(mismatch.linkedEntity);
          //mismatch.breadcrumbs = this.objectDescription(o);
          mismatch.section = this.getShortDescription(o);
          targetMismatchList.push(mismatch);
        }
      }
    }
  }

  /**
   * Get lexeme id based on runtimeId of lexical item
   * @param runtimeId 
   * @returns lexeme id
   */
  geLexemeId(runtimeId: number): number {
    if (this._entitiesByRef.has(runtimeId)) {
      const obj = this._entitiesByRef.get(runtimeId);
      if (obj && obj.type == EntityType.LEXICAL_ITEM) {
        if (obj.hypotheses && obj.hypotheses.length > 0) {
          return obj.hypotheses[0].lexeme;
        }
      }
    }
  }

  fetchPhraseDetail(srcPhraseId: number, translatedRuntimeId: number) {
    const key = `phrase_${srcPhraseId}`;
    if (this._generalStore[key])
      return this._generalStore[key]

    this._generalStore[key] = {};

    return this._phrasesService.getPhraseById(srcPhraseId).then(result => {

      const translatedPhrase = this._entitiesByRef.get(translatedRuntimeId);
      if (translatedPhrase && translatedPhrase.elements) {
        translatedPhrase.elements.forEach(item => {
          const phrasalElement = this._entitiesByRef.get(item.element_pattern_id);
          if (phrasalElement) {
            result.elements.forEach(e => {
              if (e.identity && e.identity == phrasalElement.identity) {
                const lexicalItem = this._entitiesByRef.get(item.target_node);
                if (lexicalItem)
                  e['source'] = lexicalItem.text;
              }
            })

          }
        })
        this._generalStore[key] = result;
      }
      return this._generalStore[key];
    })
  }

  isExist(array: any[], value: number | string, prop: string): boolean {
    return array.find(i => i[prop] == value)
  }

  isExistInMismatches(feature: FeatureValue, mismatches: FeatureValue[]): boolean {
    return (mismatches || []).find((e) => e.index == feature.index && e.value == feature.value) != null;
  }

  getFeatureDescription(featureTag: string, featureIndex: number, featureValue: string) {
    if (!this._features) {
      if (!featureIndex || !featureTag || !featureValue) {
        return;
      }

      const localStorageStr = this.localStorageHelper.getGlobalSetting('features');
      if (!localStorageStr)
        return;

      this._features = JSON.parse(localStorageStr);
    }

    featureTag = featureTag.toLowerCase() == 'grammar' ? 'R' : featureTag;
    featureTag = featureTag.toLowerCase() == 'style' ? 'Y' : featureTag;
    featureTag = featureTag.toLowerCase() == 'semantics' ? 'S' : featureTag;
    const featureTagMapping = {
      'R': 'Grammar',
      'Y': 'Style',
      'S': 'Semantics'
    }

    for (const element of this._features) {
      if (element.Key === featureTagMapping[featureTag]) {
        const feature = element.Value.find(feature => feature.index === featureIndex);
        if (feature) {
          const value = feature.values.find(x => x.id === featureValue);
          const description = value ? value.description : '';
          const storeKey = featureTag + featureIndex + featureValue;
          this._featureDescriptionStore[storeKey] = description;
          return description;
        }
      }
    }
  }

  getTotalColSpan(phraseLevel: { colspan: number }[]) {
    return phraseLevel.reduce((a, b) => a + b.colspan, 0);
  }

  getDeleteReason(obj) {
    if (obj.runtimeId && this._entitiesByRef.has(obj.runtimeId))
      this.updateEntity(obj, this._entitiesByRef.get(obj.runtimeId));
    return obj.deleted;
  }

  removeDuplicatedByProperty(arr: any[], prop: string) {
    return arr.filter((v, i, a) => !v[prop] || a.findIndex(t => (t[prop] === v[prop])) === i);
  }

  getLanguageName(id: number) {
    if (this._language && this._language.languagesList && this._language.languagesList.length) {
      return this._language.languagesList.find(e => e.id == id).englishName;
    } else {
      return id;
    }
  }

  getUniqueByProp(arr: any[], prop: string) {
    const types = [];
    arr.forEach(item => {
      if (!types.includes(item[prop]))
        types.push(item[prop]);
    })
    return types;
  }

  getPhraseIdentityDescription(familyId: number, identity: number) {
    if (!familyId || !identity)
      return null;

    const key = `PhraseIdentityDescription_${familyId}_${identity}`;
    if (this._generalStore[key] !== undefined)
      return this._generalStore[key];

    this._generalStore[key] = null;
    return this._identityRolesService.getIdentityDescription(familyId, identity)
      .then(description => {
        this._generalStore[key] = description;
        return this._generalStore[key];
      })
  }

  getElementTitle(el) {
    let types = [];


    if (el.matches) {
      for (const match of el.matches) {
        if (match.type == 'sense') {
          types.push('Senses');
        }
        if (this.subphrases.has(match.ref) && types.indexOf('Subphrases') == -1) {
          types.push('Subphrases');
        }

        if (this._entitiesByRef.has(match.ref) && this._entitiesByRef.get(match.ref).type == 'hypothesis' && types.indexOf('Hypothesis') == -1) {
          types.push('Hypothesis');
        }
      }
    }

    if (types.length > 0) {
      return types.join(' / ') + ' that matched';
    }
    return '';
  }

}