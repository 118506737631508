import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { LocalStorageHelper } from '../helpers/localhost.helper';
import {environment} from '../../../environments/environment';
import { Observable } from 'rxjs/Observable';


/**
 * Collection of low-level methods interacting with the LaMP API. 
 */
@Injectable()
export class ApiClientService {
  constructor(private httpClient: HttpClient,
    private localStorageHelper: LocalStorageHelper,
    ) 
  {

  }

  /**
   * Creates a full URL based on the server path (folder + web method) and the host name.
   * @param serverPath the server path
   * @returns full URL
   */
  public generateUrl(serverPath: string): string {
    return environment.apiEndpoint + serverPath;
  }


  /**
   * Gets the session token used to communicate with the LaMP web service.
   * @returns LaMP web service session token
   */
  public getToken(): string {
    if (!this.localStorageHelper)
      return "";
    return this.localStorageHelper.getGlobalSetting("auth");
  }

  /**
   * Invokes a GET request synchronously.
   * @param url the URL to invoke
   * @returns whatever the web service returns
   */
  public getSync(url: string): Observable<any> {
    let token: string = this.getToken();
    return this.httpClient.get<any>(this.generateUrl(url), 
    {
      headers: {'Authorization': token}
    });
  }

  /**
   * Invokes a GET request *a*synchronously.
   * @param url the URL to invoke
   * @returns whatever the web service returns
   */
  public get(url: string): Promise<any> {
    let token: string = this.getToken();
    if (!token || token.length < 1)
      return Promise.resolve({"success":false, "error": "Token not defined"});
   return this.httpClient.get<any>(this.generateUrl(url), 
        {
          headers: {'Authorization': token}
        })
      .toPromise()
      .then(data => {
        return data;
      }, (response) => {
        console.error('Error issuing GET request', response.error);
        return {"success":false, "error": response.error};
      });
  }

  
  /**
   * Invokes a POST method (asynchronously)
   * @param url the URL to invoke
   * @param body (optional) the body for the POST request; if object, it will be converted to JSON
   * @returns whatever the web service returns
   */
  public post(url: string, body?: object | string): Promise<any> {
    let token: string = this.getToken();
    if (!token || token.length < 1)
      return Promise.resolve({"success":false, "error": "Token not defined"});
    let normalizedBody: string;
    if (typeof body === 'string')
      normalizedBody = body;
    else
      normalizedBody = JSON.stringify(body);
    return this.httpClient.post<any>(this.generateUrl(url), normalizedBody, 
    {
      headers: {'Authorization': token}
    })
      .toPromise()
      .then(response => {
        return response;
      }, (error) => {
        console.error('Error issuing POST request', error);
        return {"success":false, "error": error};
      });
  }

  /**
   * Invokes a PUT method (asynchronously)
   * @param url the URL to invoke
   * @param body (optional) the body for the POST request; if object, it will be converted to JSON
   * @returns whatever the web service returns
   */
  public put(url: string, body?: object | string): Promise<any> {
    let token: string = this.getToken();
    if (!token || token.length < 1)
      return Promise.resolve({"success":false, "error": "Token not defined"});

    let normalizedBody: string;
    if (typeof body === 'string')
      normalizedBody = body;
    else
      normalizedBody = JSON.stringify(body);

    return this.httpClient.put<any>(this.generateUrl(url), normalizedBody || null,
      {
        headers: {'Authorization': token}
      })
      .toPromise()
      .then(data => {
        return data;
      }, (error) => {
        console.error('Error issuing PUT request', error);
        return {"success":false, "error": error};
      });

  }

  /**
   * Invokes a DELETE method (asynchronously)
   * @param url the URL to invoke
   * @returns whatever the web service returns
   */
  public deleteRequest(url: string): Promise<any> {
    let token: string = this.getToken();
    if (!token || token.length < 1)
      return Promise.resolve({"success":false, "error": "Token not defined"});
    return this.httpClient.delete<any>(this.generateUrl(url), 
      {
        headers: {'Authorization': token}
      })
      .toPromise()
      .then(data => {
        return data;
      }, (error) => {
        console.error('Error issuing DELETE request: ', error);
        return {"success":false, "error": error};
      });
  }
}


