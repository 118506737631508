import { FeatureValue, ModelWithRelevantFeature } from "./feature";

export interface InflectionList {
  auditUnavailable?: boolean;
  failures?: FailuresByStem[];
  lines?: Line[];
}

export interface FailuresByStem {
  Key: string; // stem or lemma
  Value: FailuresByTrigger[];
}

export interface FailuresByTrigger {
  Key: string; // trigger description
  Value: Failure[];
}

export interface PagedFailures {
  Description: string;
  Values: Failure[];
  hasSuccess?: boolean;
}

export interface Failure {
  assigned?: string;
  hypernyms?: boolean;
  id?: number;
  incompatible?: boolean;
  pattern?: string;
  phoneticFailure?: string;
  features?: string;
  reason?: string;
  required?: string;
  trigger?: string;
  spawnedFrom?: number;
  wordPart?: boolean;
  madeRedundantByPattern?: number;
  madeRedundantByForm?: string;
}

export interface Line {
  text?: string;
  tooltip?: string;
  indent?: number;
  features?: FeatureValue[];
  highlight?: boolean;
  inflectionId?: number;
  inflection?: boolean;
  generatedBy?: GeneratedBy;
  stemGeneratedBy?: GeneratedBy;
  wordPart?: boolean;
  families?: number[];
  hyphen?: string;
  misspelled?: boolean;
  lemma?: boolean;
  lastResort?: boolean;
  legacy?: number;
  required?: boolean;
  auditResult?: 'redundant' | 'match' | 'wrong';
  externalMatch?: string;
}

export interface Inflection {
  additionalForms?: string;
  advancedCriteriaDescription?: string;
  advancedCriteriaId?: number;
  familyFeatures?: FeatureValue[];
  id?: number;
  inflectedForm?: string;
  inflectionFeatures?: FeatureValue[];
  isLemma?: boolean;
  legacyInflectionMapId?: number;
  lexemeFamilyFeatures?: FeatureValue[];
  lexemeFeatures?: FeatureValue[];
  misspelling?: boolean;
  note?: string;
}

export interface GeneratedBy {
  assigned?: string;
  hypernyms?: boolean;
  id?: number;
  propagatedId?: number;
  incompatible?: boolean;
  pattern?: string;
  reason?: string;
  required?: string;
  trigger?: string;
}

export interface AdvancedInflection extends ModelWithRelevantFeature {
  additionalForms?: string;
  advancedCriteriaDescription?: string;
  advancedCriteriaId?: number;
  familyFeatures?: FeatureValue[];
  features?: FeatureValue[];
  inflectionFeatures?: FeatureValue[];
  inflectionId?: number;
  isLemma?: boolean;
  legacyInflectionMapId?: number;
  lexemeFamilyFeatures?: FeatureValue[];
  lexemeFeatures?: FeatureValue[];
  lexemeId?: number;
  misspelling?: boolean;
  note?: string;
  text?: string;
  familyGrammar?: FeatureValue[];
  familySemantics?: FeatureValue[];
  lexemeFamilyStyle?: FeatureValue[];
  lexemeGrammar?: FeatureValue[];
  lexemeStyle?: FeatureValue[];
  lexemeSemantic?: FeatureValue[];
  inflectionGrammar?: FeatureValue[];
  inflectionStyle?: FeatureValue[];
  inflectionSemantic?: FeatureValue[];
  familyId?: number;
  lastResort?: boolean;
  extraHypothesis?: GeneratedBy;
}

export const emplyAdvancedInflection: AdvancedInflection = {
  additionalForms: '',
  advancedCriteriaDescription: '',
  advancedCriteriaId: 0,
  familyFeatures: [],
  features: [],
  inflectionFeatures: [],
  inflectionId: 0,
  isLemma: false,
  legacyInflectionMapId: 0,
  lexemeFamilyFeatures: [],
  lexemeFeatures: [],
  lexemeId: 0,
  misspelling: false,
  note: '',
  text: '',
  familyGrammar: [],
  familySemantics: [],
  lexemeFamilyStyle: [],
  lexemeGrammar: [],
  lexemeStyle: [],
  inflectionGrammar: [],
  inflectionStyle: [],
  inflectionSemantic: [],
  familyId: 0
};

export interface Contraction {
  id?: number;
  contractedForm?: string;
  lexemeId1?: number;
  inflectionId1?: number;
  inflectionId1Description?: string;
  familyId1?: number;
  familyId1Description?: string;
  lexemeId2?: number;
  inflectionId2?: number;
  inflectionId2Description?: string;
  familyId2?: number;
  familyId2Description?: string;
  lexemeId3?: number;
  inflectionId3?: number;
  inflectionId3Description?: string;
  familyId3?: number;
  familyId3Description?: string;
  useOnGeneration?: boolean;
}

export const emptyContraction: Contraction = {
  contractedForm: "",
  familyId1: 0,
  inflectionId1: 0,
  familyId2: 0,
  inflectionId2: 0,
  familyId3: 0,
  inflectionId3: 0,
  useOnGeneration: false,
};

export interface DropdownSetup {
  inflection: {
    id: number;
    description: string;
  }[];
  family: {
    id: number;
    description: string;
  }[];
}
