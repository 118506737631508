import {Injectable} from '@angular/core';

import {EndpointUrls} from '../constants/endpoint';

import {ApiClientService} from './api-client.service';
import {LampUpdateResponse} from '../models/common';
import {LinguisticRange, LinguisticRangeList, LinguisticRangeLocalization} from '../models/range';
import {SessionData} from '../models/session';

@Injectable()
export class RangeService {

  constructor(private apiClientService: ApiClientService) {
  }

  public getRangeList(): Promise<LinguisticRangeList[]> {
    return this.apiClientService
      .get(EndpointUrls.range.rangeList)
      .then((rangeList) => {
        return (rangeList as LinguisticRangeList[]);
      });
  }

  public getRangeById(id: number): Promise<LinguisticRange> {
    return this.apiClientService
      .get(`${EndpointUrls.range.range}?id=${id}`)
      .then((range: object) => {
        return (range as LinguisticRange);
      });
  }

  public initRange(tableName: string): Promise<LinguisticRange> {
    return this.apiClientService
      .get(`${EndpointUrls.range.initRange}?table=${tableName}`)
      .then((range: object) => {
        return (range as LinguisticRange);
      });
  }

  public updateRange(range: LinguisticRange): Promise<LampUpdateResponse> {
    return this.apiClientService
      .put(EndpointUrls.range.range, range)
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

  public deleteRangeById(id: number): Promise<LampUpdateResponse> {
    return this.apiClientService
      .deleteRequest(`${EndpointUrls.range.range}?id=${id}`)
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

  public createRange(range: LinguisticRange): Promise<LampUpdateResponse> {
    return this.apiClientService
      .post(EndpointUrls.range.range, range)
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

  public getRangeLocalizationList(id: number): Promise<LinguisticRangeLocalization[]> {
    return this.apiClientService
      .get(`${EndpointUrls.range.localizationList}?id=${id}`)
      .then((rangeList) => {
        return (rangeList as any[]);
      });
  }

  public getRangeLocalizationById(id: number, languageId: number): Promise<LinguisticRangeLocalization> {
    return this.apiClientService
      .get(`${EndpointUrls.range.localization}?id=${id}&language=${languageId}`)
      .then((range: object) => {
        return (range as LinguisticRangeLocalization);
      });
  }

  public updateRangeLocalization(rangeLocalization: LinguisticRangeLocalization): Promise<LampUpdateResponse> {

      return this.apiClientService
        .put(EndpointUrls.range.localization, rangeLocalization)
        .then((resultMessage: LampUpdateResponse) => {
          return resultMessage;
        });
  }

  public deleteRangeLocalizationById(id: number, languageId: number): Promise<LampUpdateResponse> {
    return this.apiClientService
      .deleteRequest(`${EndpointUrls.range.localization}?id=${id}&language=${languageId}`)
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

  public createRangeLocalization(rangeLocalization: LinguisticRangeLocalization): Promise<LampUpdateResponse> {
    return this.apiClientService
      .post(EndpointUrls.range.localization, rangeLocalization)
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

}
