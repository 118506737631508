import {Directive, HostListener, Input, Output, EventEmitter} from '@angular/core';

import {MatDialog} from '@angular/material';

import {PunctuationLookupComponent} from './punctuation-lookup.component';

@Directive({
  selector: '[appOpenPunctuationLookup]'
})
export class OpenPunctuationLookupDirective {

  @Input() isLookup: boolean;

  @Output() closeDialogEvent: EventEmitter<number> = new EventEmitter<number>();

  private isMobileView: boolean;

  constructor(private dialog: MatDialog) {
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  @HostListener('click') openDialog() {
    const dialogRef = this.dialog.open(PunctuationLookupComponent, {
      minWidth: this.isMobileView ? '100%' : '90%',
      data: {
        isLookup: this.isLookup
      }
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.closeDialogEvent.emit(result);
        }
      });
  }

}
