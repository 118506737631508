import { trigger, state, transition, animate, style } from '@angular/animations';

/**
 * UNUSED
 */
export class Animations {
  public static slideInOut = trigger('slideInOut', [
    state('true', style({ height: '0px', opacity: 0, display: 'none' })),
    state('false', style({ height: '*', opacity: 1, display: 'block' })),
    transition('1 => 0', animate('500ms ease-in')),
    transition('0 => 1', animate('500ms ease-out'))
  ]);
}
