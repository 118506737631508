import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';

import { AffixTestListDialogComponent } from './affix-test-list-dialog.component';


@Directive({ selector: '[appOpenAffixTestListDialog]' })

export class ButtonOpensAffixTestListDialogDirective {
  @Input() isTaggingAffixesMenu: boolean;
  @Input('affixGroupId') affixGroupId: number;
  @Output() beforeDialogClosedEvent: EventEmitter<boolean> = new EventEmitter();

  private isMobileView: boolean;

  constructor(public dialog: MatDialog) {
    this.isMobileView = window.navigator.userAgent.toLowerCase().includes('mobi');
  }

  @HostListener('click') openAffixTestListDialog() {
    const dialogRef: MatDialogRef<any> = this.dialog.open(AffixTestListDialogComponent, {
      data: {
        isTaggingAffixesMenu: this.isTaggingAffixesMenu,
        affixGroupId: this.affixGroupId
      },
      width: this.isMobileView ? '96%' : '70%',
    });

    dialogRef.beforeClosed().subscribe(() => {
      this.beforeDialogClosedEvent.emit(true);
    });
  }
}
