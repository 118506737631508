import {Injectable} from '@angular/core';

import {EndpointUrls} from '../constants/endpoint';
import {ApiClientService} from './api-client.service';
import {LampUpdateResponse} from '../models/common';
import {SessionService} from './session.service';

import { errorMessages } from '../constants/application-constants';
import {FamilyDefinition, KnowledgeGraphElement, LinksTypes} from '../models/knowledge-graph';
import {Lexeme} from '../models/lexicon';
import { LocalStorageHelper } from '../helpers/localhost.helper';
import { PhraseInTheEditView } from '../models/phrases.model';

@Injectable()
export class KnowledgeGraphService {

  constructor(private apiClientService: ApiClientService,
    private sessionService: SessionService,
    private localStorageHelper: LocalStorageHelper) {
  }

  public getKnowledgeGraph(searchArgument: string,
                                                  searchArgumentType: string,
                                                  basic?: string): Promise<KnowledgeGraphElement[]> {
    let endPointUrl = `${EndpointUrls.knowledgeGraph.knowledgeGraph}?arg=${searchArgument}&type=${searchArgumentType}`;
    if (typeof basic !== 'undefined') {
      endPointUrl += `&basic=${basic}`;
    }
    return this.apiClientService
      .get(endPointUrl)
      .then((knowledgeGraphNodes) => {
        return knowledgeGraphNodes;
      });
  }

  public getHyponymFamiliesListForTheSpecifiedFamily(familyId: string,
                                                     rangeId?: string): Promise<any> {
    let endPointUrl = `${EndpointUrls.knowledgeGraph.hyponymList}?family=${familyId}`;
    if (typeof rangeId !== 'undefined') {
      endPointUrl += `&range=${rangeId}`;
    }
    return this.apiClientService
      .get(endPointUrl)
      .then((hyponymFamiliesList) => {
        return hyponymFamiliesList;
      });
  }

  public getAntonymFamiliesListForTheSpecifiedFamily(familyId: string): Promise<any> {
    let endPointUrl = `${EndpointUrls.knowledgeGraph.antonymList}?family=${familyId}`;
    return this.apiClientService
    .get(endPointUrl)
    .then((antonyms) => {
      return antonyms;
    });
}



  public getDomainMemberFamiliesListForTheSpecifiedFamily(familyId: string,
                                                          rangeId?: string): Promise<any> {
    let endPointUrl = `${EndpointUrls.knowledgeGraph.domainMemberList}?family=${familyId}`;
    if (typeof rangeId !== 'undefined') {
      endPointUrl += `&range=${rangeId}`;
    }
    return this.apiClientService
      .get(endPointUrl)
      .then((domainMemberFamiliesList) => {
        return domainMemberFamiliesList;
      });
  }

  public addSilentDomains(domainMember: number, domains: string): Promise<LampUpdateResponse> {
    return this.apiClientService
      .post(`${EndpointUrls.knowledgeGraph.silentDomains}?member=${domainMember}&domains=${domains}`)
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

  public updateFamilyLexemeLinksForTheSpecifiedFamily(familyId: string,
                                                      commaDelimitedListOfLexeme: string): Promise<LampUpdateResponse> {
    return this.apiClientService
      .post(`${EndpointUrls.knowledgeGraph.familyLexemes}?family=${familyId}&lexemes=${commaDelimitedListOfLexeme}`)
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

  public deleteFamilyLexemeLinksForTheSpecifiedFamily(familyId: string,
                                                      commaDelimitedListOfLexeme: string): Promise<LampUpdateResponse> {
    return this.apiClientService
      .deleteRequest(`${EndpointUrls.knowledgeGraph.familyLexemes}?family=${familyId}&lexemes=${commaDelimitedListOfLexeme}`)
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

  public updateFamilyLexemeLinksForTheFamilySelectionPage(familyId: string,
                                                          typeOfTheLinksToInsert: LinksTypes,
                                                          commaDelimitedListOfLexeme: string): Promise<LampUpdateResponse> {
    return this.apiClientService
      .post(`${EndpointUrls.knowledgeGraph.familyLinks}?family=${familyId}&type=${typeOfTheLinksToInsert}&links=${commaDelimitedListOfLexeme}`)
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

  public moveFamilyLexemeLinksToTheOtherFamilies(familyId: string,
                                                 commaDelimitedListOfTargetFamilies: string,
                                                 commaDelimitedListOfLexeme: string): Promise<LampUpdateResponse> {
    return this.apiClientService
      .put(`${EndpointUrls.knowledgeGraph.moveLexemeLinks}?family=${familyId}&targetFamilies=${commaDelimitedListOfTargetFamilies}&lexemeIds=${commaDelimitedListOfLexeme}`)
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

  public deleteFamilyLexemeLinksForTheFamilySelectionPage(familyId: string,
                                                          typeOfTheLinksToInsert: LinksTypes,
                                                          commaDelimitedListOfLexeme: string): Promise<LampUpdateResponse> {
    return this.apiClientService
      .deleteRequest(`${EndpointUrls.knowledgeGraph.familyLinks}?family=${familyId}&type=${typeOfTheLinksToInsert}&links=${commaDelimitedListOfLexeme}`)
      .then((resultMessage: LampUpdateResponse) => {
        return resultMessage;
      });
  }

  public getFamiliesListForTheCurrentRange(searchArgument: string,
                                           searchArgumentType: string): Promise<FamilyDefinition[]> {
    const endPointUrl = `${EndpointUrls.knowledgeGraph.findFamilies}?arg=${searchArgument}&type=${searchArgumentType}`;
    return this.apiClientService
      .get(endPointUrl)
      .then((familiesList) => {
        return familiesList as FamilyDefinition[];
      });
  }


  public getLexemesListForTheCurrentRange(searchArgument: string,
                                          searchArgumentType: string,
                                          languageMode: string): Promise<Lexeme[]> {
    const endPointUrl = `${EndpointUrls.knowledgeGraph.findLexemes}?arg=${searchArgument}&type=${searchArgumentType}&otherLanguages=${languageMode}`;
    return this.apiClientService
      .get(endPointUrl)
      .then((lexemesList) => {
        return (lexemesList as Lexeme[]);
      });
  }

  public getSuggestedLexemesList(familyId: number | string): Promise<Lexeme[]> {
    const endPointUrl = `${EndpointUrls.knowledgeGraph.suggestLexemeLinks}?family=${familyId.toString()}`;
    return this.apiClientService
      .get(endPointUrl)
      .then((lexemesList) => {
        return (lexemesList as Lexeme[]);
      });
  }

  public getFamilyById(familyId: string): Promise<FamilyDefinition> {
    const endPointUrl = `${EndpointUrls.knowledgeGraph.family}?id=${familyId}`;
    return this.apiClientService
      .get(endPointUrl)
      .then((family) => {
        return family as FamilyDefinition;
      });
  }

  public createFamily(family: FamilyDefinition): Promise<LampUpdateResponse> {
    const isAdmin = (this.sessionService.isAdmin());
    if (isAdmin) {
      return this.apiClientService
        .post(EndpointUrls.knowledgeGraph.family, family)
        .then((successfulResponseMessage: LampUpdateResponse) => {
          return successfulResponseMessage;
        });
    }
    return Promise.resolve({success: false, error: errorMessages.actionIsNotAllowed} as LampUpdateResponse);
  }

  public updateFamily(family: FamilyDefinition): Promise<LampUpdateResponse> {
    const isAdmin = this.sessionService.isAdmin();
    if (isAdmin) {
      return this.apiClientService
        .put(EndpointUrls.knowledgeGraph.family, family)
        .then((successfulResponseMessage: LampUpdateResponse) => {
          return successfulResponseMessage;
        });
    }
    return Promise.resolve({success: false, error: errorMessages.actionIsNotAllowed} as LampUpdateResponse);
  }

  public deleteFamily(familyId: string): Promise<LampUpdateResponse> {
    const isAdmin = this.sessionService.isAdmin();
    if (isAdmin) {
      const endPointUrl = `${EndpointUrls.knowledgeGraph.family}?id=${familyId}`;
      return this.apiClientService
        .deleteRequest(endPointUrl)
        .then((successfulResponseMessage: LampUpdateResponse) => {
          return successfulResponseMessage;
        });
    }
    return Promise.resolve({success: false, error: errorMessages.actionIsNotAllowed} as LampUpdateResponse);
  }

  public getFamilyLocalizationById(familyId: string): Promise<FamilyDefinition> {
    const endPointUrl = `${EndpointUrls.knowledgeGraph.familyLocalization}?id=${familyId}`;
    return this.apiClientService
      .get(endPointUrl)
      .then((family) => {
        return family as FamilyDefinition;
      });
  }


  public updateFamilyLocalization(family: FamilyDefinition): Promise<LampUpdateResponse> {
    return this.apiClientService
      .put(EndpointUrls.knowledgeGraph.familyLocalization, family)
      .then((successfulResponseMessage: LampUpdateResponse) => {
        return successfulResponseMessage;
      });
  }

  public async getFamilyDescription(familyId: number): Promise<string> {
    let description = this.localStorageHelper.getTabSetting(familyId.toString());
    if (!description) {
      const family = await this.getFamilyById(familyId.toString());
      description = family.description;
      this.localStorageHelper.setTabSetting(familyId.toString(), description);
    }
    return description;
  }

  private getPhraseById(id: number): Promise<PhraseInTheEditView> {
    return this.apiClientService.get(`${EndpointUrls.phrases.phrase}?id=${id}`);
  }

  public async getFamilyPhraseDescription(familyId: number, phraseId: number): Promise<{ familyDescription: string, phraseDescription: string }> {
    let cacheString = this.localStorageHelper.getTabSetting(phraseId.toString());
    let familyDescription, phraseDescription;
    if (!cacheString) {
      const phrase = await this.getPhraseById(phraseId);
      phraseDescription = phrase.description;
      familyDescription = await this.getFamilyDescription(familyId);
      this.localStorageHelper.setTabSetting(phraseId.toString(), JSON.stringify({ familyDescription, phraseDescription }));
    } else {
      familyDescription = JSON.parse(cacheString).familyDescription;
      phraseDescription = JSON.parse(cacheString).phraseDescription;
    }
    return { familyDescription, phraseDescription };
  }
}
