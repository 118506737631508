import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {RootPageComponent} from './basic/root-page/root-page.component';
import {LoginPageComponent} from './basic/login-page/login-page.component';
import {LexiconComponent} from './lexicon/lexicon.component';
import {LanguageAndStatisticMenuComponent} from './basic/language-and-statistic-menu/language-and-statistic-menu.component';
import {PunctuationMenuComponent} from './punctuation/punctuation-menu/punctuation-menu.component';

import {PunctuationEditComponent} from './punctuation/punctuation-edit/punctuation-edit.component';
import {PunctuationSignDataResolver} from './shared/resolvers/punctuation-sign-data.resolver';
import {PunctuationSignListResolver} from './shared/resolvers/punctuation-sign-list.resolver';
import {GetIdFromRouterParametersResolver} from './shared/resolvers/get-id-from-route-parameters.resolver';
import {GetValueFromRouterParametersResolver} from './shared/resolvers/get-value-from-route-parameters.resolver';
import {GetTypeFromRouterParametersResolver} from './shared/resolvers/get-type-from-route-parameters.resolver';

import {PhonemesMenuComponent} from './phonemes/phonemes-menu/phonemes-menu.component';
import {PhonemesEditComponent} from './phonemes/phonemes-edit/phonemes-edit.component';

import {WelcomePageComponent} from './basic/welcome-page/welcome-page.component';
import {ErrorPageComponent} from './basic/error-page/error-page.component';

import {AdvancedCriteriaEditComponent} from './lexicon/advanced-criteria-edit/advanced-criteria-edit.component';
// Administration
import {LanguageSettingsComponent} from './administration/language-settings/language-settings.component';
import {FeatureLocalizationEditComponent} from './administration/feature-localization-edit/feature-localization-edit.component';
import {FeatureLocalizationMenuComponent} from './administration/feature-localization-menu/feature-localization-menu.component';
import {FeatureCategoryEditComponent} from './administration/feature-category-edit/feature-category-edit.component';
import {FeatureEditComponent} from './administration/feature-edit/feature-edit.component';
import {RangesDefinitionsComponent} from './administration/ranges/ranges-definitions/ranges-definitions.component';
import {RangeEditComponent} from './administration/ranges/range-edit/range-edit.component';
import {DefinitionMenuItemComponent} from './administration/definition-menu-item/definition-menu-item.component';

// Guards
import {
  PassAuthenticatedUserGuard, PassEverybodyExceptGuestGuard,
  PassNonAuthenticatedUserGuard
} from './shared/services/authentication-guard.service';

// Morphology
import {ExtraHypothesisComponent} from './morphology/extra-hypothesis/extra-hypothesis.component';

// Knowledge Graph
import {KnowledgeGraphMenuComponent} from './knowledge-graph/knowledge-graph-menu/knowledge-graph-menu.component';
import {KnowledgeGraphElementAdvancedEditComponent} from './knowledge-graph/knowledge-graph-element-advanced-edit/knowledge-graph-element-advanced-edit.component';

// Lexicon
import {LexemeEditComponent} from './lexicon/lexeme-edit/lexeme-edit.component';
import {LexemeFamilyConnectionEditComponent} from './lexicon/lexeme-family-connection-edit/lexeme-family-connection-edit.component';
import {InflectionTableComponent} from './inflection-table/inflection-table.component';
import {ExtraHypothesisEditComponent} from './morphology/extra-hypothesis/extra-hypothesis-edit/extra-hypothesis-edit.component';
import {InterfixListComponent} from './morphology/interfix-module/interfix-list/interfix-list.component';
import {InterfixEditComponent} from './morphology/interfix-module/interfix-edit/interfix-edit.component';
import {VisualTracerComponent} from './visual-tracer/tracer.component';
import {TestResultsComponent} from './results/testresults.component';
import {CorporaListComponent} from './corpora/corpora-list.component';
import {TestProviderComponent} from './testproviders/testprovider-list.component';
import {TestproviderEditComponent} from './testproviders/edit/testprovider-edit.component';
import { CorporaEditComponent } from './corpora/corpora-edit/corpora-edit.component';
import { TestfragmentEditComponent } from './corpora/testfragment-edit/testfragment-edit.component';
import { TestConsoleComponent } from './test-console/testconsole.component';
import { NonbreakListComponent } from './non-break/nonbreak-list.component';
import { ResultReviewComponent } from './corpora/review/result-review.component';
import { LinguistActivitiesComponent } from './administration/linguist-activities/linguist-activities.component';
import { ResultReviewTransformationComponent } from './corpora/review-transformation/result-review-transformation.component';
import { RestartLampServiceComponent } from './administration/restart-lamp-service/restart-lamp-service.component';
import { AboutComponent } from './about/about.component';


const appRoutes: Routes = [
  {
    path: '', component: RootPageComponent, children: [
    {path: 'welcome', component: WelcomePageComponent, canActivate: [PassAuthenticatedUserGuard]},
    {path: 'error', component: ErrorPageComponent},
    {path: 'login', component: LoginPageComponent, canActivate: [PassNonAuthenticatedUserGuard]},
    {path: 'profile', loadChildren: './basic/user-profile/user-profile.module#UserProfileModule'},
    {path: 'edits-list', loadChildren: './basic/edits-list/edits-list.module#EditsListModule'},
    {
      path: 'content/advanced-criteria/:currentId/:forWords', component: AdvancedCriteriaEditComponent,
      canActivate: [PassAuthenticatedUserGuard]
    },
    {
      path: 'lexicon', component: LexiconComponent,
      canActivate: [PassAuthenticatedUserGuard],
      canActivateChild: [PassAuthenticatedUserGuard, PassEverybodyExceptGuestGuard]
    },
    {
      path: 'lexicon/lexeme/edit/:id', component: LexemeEditComponent,
      resolve: {
        id: GetIdFromRouterParametersResolver
      },
      canActivate: [PassAuthenticatedUserGuard]
    },
    {
      path: 'lexicon/lexeme/copy/:id', component: LexemeEditComponent,
      resolve: {
        id: GetIdFromRouterParametersResolver
      },
      canActivate: [PassAuthenticatedUserGuard]
    },
    {
      path: 'lexicon/lexeme/create', component: LexemeEditComponent,
      canActivate: [PassAuthenticatedUserGuard]
    },
    {
      path: 'lexicon/lexeme-family-connection/edit/:id/:value', component: LexemeFamilyConnectionEditComponent,
      resolve: {
        id: GetIdFromRouterParametersResolver,
        value: GetValueFromRouterParametersResolver
      },
      canActivate: [PassAuthenticatedUserGuard]
    },
    {
      path: 'lexicon/lexeme-family-connection/create/:id', component: LexemeFamilyConnectionEditComponent,
      resolve: {
        id: GetIdFromRouterParametersResolver
      },
      canActivate: [PassAuthenticatedUserGuard]
    },
    {
      path: 'inflection-table/:lexeme/:lexemeId', component: InflectionTableComponent,
      canActivate: [PassAuthenticatedUserGuard]
    },
    {path: 'visual-tracer', component: VisualTracerComponent, canActivate: [PassAuthenticatedUserGuard]},
    {path: 'statistics', component: LanguageAndStatisticMenuComponent, canActivate: [PassAuthenticatedUserGuard]},
    {path: 'test-overview', component: TestResultsComponent, canActivate: [PassAuthenticatedUserGuard]},
    {path: 'corpora', component: CorporaListComponent, canActivate: [PassAuthenticatedUserGuard]},
    {path: 'corpora/edit/:id', component: CorporaEditComponent, canActivate: [PassAuthenticatedUserGuard]},
    {path: 'corpora/create', component: CorporaEditComponent, canActivate: [PassAuthenticatedUserGuard]},
    {path: 'testfragments/create/:corporaId', component: TestfragmentEditComponent, canActivate: [PassAuthenticatedUserGuard]},
    {path: 'testfragments/edit/:id', component: TestfragmentEditComponent, canActivate: [PassAuthenticatedUserGuard]},
    {path: 'testproviders', component: TestProviderComponent, canActivate: [PassAuthenticatedUserGuard]},
      { path: 'verify-results-analysis', component: ResultReviewComponent, canActivate: [PassAuthenticatedUserGuard] },
      { path: 'verify-results-transformation', component: ResultReviewTransformationComponent, canActivate: [PassAuthenticatedUserGuard] },
    {path: 'testproviders/edit/:id', component: TestproviderEditComponent, canActivate: [PassAuthenticatedUserGuard]},
    {path: 'test-console', component: TestConsoleComponent, canActivate: [PassAuthenticatedUserGuard]},
    {path: 'non-breaks', component: NonbreakListComponent, canActivate: [PassAuthenticatedUserGuard]},
    {
      path: 'punctuation',
      component: PunctuationMenuComponent,
      canActivate: [PassAuthenticatedUserGuard],
    },
    {
      path: 'punctuation/edit/:id',
      component: PunctuationEditComponent,
      resolve: {
        punctuationSignData: PunctuationSignDataResolver,
        punctuationSignList: PunctuationSignListResolver,
        id: GetIdFromRouterParametersResolver
      },
    },
    {
      path: 'punctuation/create/:id',
      component: PunctuationEditComponent,
      resolve: {
        punctuationSignList: PunctuationSignListResolver,
        id: GetIdFromRouterParametersResolver
      },
    },
    {
      path: 'phonemes',
      component: PhonemesMenuComponent,
      canActivate: [PassAuthenticatedUserGuard],
    },
    {
      path: 'phonemes/edit/:id',
      component: PhonemesEditComponent,
      resolve: {
        id: GetIdFromRouterParametersResolver
      },
    },
    {
      path: 'phonemes/create/:id',
      component: PhonemesEditComponent,
      resolve: {
        id: GetIdFromRouterParametersResolver
      },
    },
    {
      path: 'administration',
      canActivate: [PassAuthenticatedUserGuard],
      canActivateChild: [PassAuthenticatedUserGuard],
      children: [
        {path: 'language-settings', component: LanguageSettingsComponent},
        {path: 'features-localization', component: FeatureLocalizationMenuComponent},
        {
          path: 'features-localization/edit/:id', component: FeatureLocalizationEditComponent,
          resolve: {
            id: GetIdFromRouterParametersResolver
          }
        },
        {
          path: 'features-localization/edit/:id/feature/:value', component: FeatureLocalizationEditComponent,
          resolve: {
            id: GetIdFromRouterParametersResolver,
            value: GetValueFromRouterParametersResolver
          }
        },
        {path: 'features-definition', component: DefinitionMenuItemComponent},
        {
          path: 'features-definition/category/edit/:id', component: FeatureCategoryEditComponent,
          resolve: {
            id: GetIdFromRouterParametersResolver
          }
        },
        {
          path: 'features-definition/definition/edit/:id/:value', component: FeatureEditComponent,
          resolve: {
            id: GetIdFromRouterParametersResolver,
            value: GetValueFromRouterParametersResolver
          }
        },
        {
          path: 'features-definition/category/create/:type', component: FeatureCategoryEditComponent,
          resolve: {
            id: GetIdFromRouterParametersResolver,
            type: GetTypeFromRouterParametersResolver
          }
        },
        {
          path: 'features-definition/definition/create/:type/:id', component: FeatureEditComponent,
          resolve: {
            id: GetIdFromRouterParametersResolver,
            type: GetTypeFromRouterParametersResolver
          }
        },
        {path: 'data-filters', component: RangesDefinitionsComponent},
        {
          path: 'data-filters/create/:type', component: RangeEditComponent,
          resolve: {
            type: GetTypeFromRouterParametersResolver
          }
        },
        {
          path: 'data-filters/edit/:type/:id', component: RangeEditComponent,
          resolve: {
            id: GetIdFromRouterParametersResolver
          }
        },
        {path: 'linguist-activities', component: LinguistActivitiesComponent},
        { path: 'restart-lamp-service', component: RestartLampServiceComponent },
      ]
    },
    {
      path: 'advanced',
      canActivate: [PassAuthenticatedUserGuard],
      canActivateChild: [PassAuthenticatedUserGuard],
      children: [
        {
          path: 'coreference-resolution',
          loadChildren: './advanced/coreference-resolution/coreference-resolution.module#CoreferenceResolutionModule'
        },
        {
          path: 'non-dictionary-heuristics',
          loadChildren: './advanced/non-dictionary-heuristics/non-dictionary-heuristics.module#NonDictionaryHeuristicsModule'
        },
        {
          path: 'etymology',
          loadChildren: './etymology/etymology.module#EtymologyModule'
        },
        {
          path: 'pro-drop',
          loadChildren: './advanced/pro-drop/pro-drop.module#ProdropModule'
        },
        {
          path: 'contractions',
          loadChildren: './advanced/contraction/contraction.module#ContractionModule'
        },
      ]
    },
    {
      path: 'element-clause-edit',
      loadChildren: './universal-components/element-clause-editor/element-clause-editor.module#ElementClauseEditorModule'
    },
    {
      path: 'syntax-and-context',
      canActivate: [PassAuthenticatedUserGuard],
      canActivateChild: [PassAuthenticatedUserGuard],
      children: [
        {
          path: 'grammatical-agreement',
          loadChildren: './syntax-and-context/grammatical-agreement/grammatical-agreement.module#GrammaticalAgreementModule'
        },
        {
          path: 'phrasal-patterns',
          loadChildren: './syntax-and-context/phrasal-patterns/phrasal-patterns.module#PhrasalPatternsModule'
        },
        {
          path: 'commonsense-cues',
          loadChildren: './syntax-and-context/common-cues/common-cues.module#CommonSenseCuesModule'
        },
      ]
    },
    {
      path: 'morphology', canActivate: [PassAuthenticatedUserGuard],
      canActivateChild: [PassAuthenticatedUserGuard],
      children: [
        {path: 'clitics', loadChildren: './morphology/clitics/clitics.module#CliticsModule'},
        {
          path: 'affixes',
          loadChildren: './morphology/tagging-and-inflection/tagging-and-inflection.module#TaggingAndInflectionModule'
        },
        {
          path: 'default-feature-values',
          loadChildren: './morphology/default-feature-values/default-feature-values.module#DefaultFeatureValuesModule'
        },
        {
          path: 'extra-hypothesis-generation', component: ExtraHypothesisComponent
        },
        {
          path: 'extra-hypothesis-generation/edit/:id', component: ExtraHypothesisEditComponent,
          resolve: {
            id: GetIdFromRouterParametersResolver
          }
        },
        {
          path: 'extra-hypothesis-generation/create', component: ExtraHypothesisEditComponent
        },
        {
          path: 'interfixes', component: InterfixListComponent
        },
        {
          path: 'interfixes/edit/:id', component: InterfixEditComponent,
          resolve: {
            id: GetIdFromRouterParametersResolver
          }
        },
        {
          path: 'interfixes/create', component: InterfixEditComponent
        },
      ]
    },
    {
      path: 'knowledge-graph',
      component: KnowledgeGraphMenuComponent,
      canActivate: [PassAuthenticatedUserGuard],
    },
    {
      path: 'knowledge-graph/advanced-edit/edit/:id', component: KnowledgeGraphElementAdvancedEditComponent,
      canActivate: [PassAuthenticatedUserGuard],
      resolve: {
        id: GetIdFromRouterParametersResolver
      },
    },
    {
      path: 'knowledge-graph/family-create', component: KnowledgeGraphElementAdvancedEditComponent,
      canActivate: [PassAuthenticatedUserGuard],
      resolve: {
        id: GetIdFromRouterParametersResolver
      },
    },
      {
        path: 'about',
        component: AboutComponent,
        canActivate: [PassAuthenticatedUserGuard],
      },
    {path: '404', redirectTo: 'error'},
    {path: '**', redirectTo: 'welcome'},
  ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule],
  providers: [
    PassAuthenticatedUserGuard,
    PassNonAuthenticatedUserGuard,
    PassEverybodyExceptGuestGuard]
})

export class AppRoutingModule {
}
