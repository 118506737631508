import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import  { KnowledgeGraphLink } from '../../shared/models/knowledge-graph';
import { LexiconService } from '../../shared/services/lexicon.service';
import { LampUpdateResponse } from '../../shared/models/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { Lexeme } from '../../shared/models/lexicon';
import { KnowledgeGraphService } from '../../shared/services/knowledge-graph.service';

@Component({
  selector: 'app-changeable-list',
  templateUrl: './changeable-list.component.html',
  styleUrls: ['./changeable-list.component.less']
})
export class ChangeableListComponent implements OnChanges {
  @Input() list: KnowledgeGraphLink[];
  @Input() isAbleToReplaceLinks: boolean;
  @Input() isAbleToAddSlientDomain: boolean;
  @Input() isAdmin: boolean;
  @Input() isLexemesDialog: boolean;
  @Input() isFamilyDialog: boolean;
  @Input() representativeLemma: string;
  @Input() header: string;
  @Input() familyId: number;

  public commaDelimitedIdsList: string = '';
  public isLoading: boolean = false;

  @Output() addSilentDomainsEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() refreshFamilyEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteLinksEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() moveLinksEvent: EventEmitter<{ commaDelimitedIdsList: string, commaDelimitedListOfTargetFamilies: string }> =
    new EventEmitter<{ commaDelimitedIdsList: string, commaDelimitedListOfTargetFamilies: string }>();

  @Output() addLinksEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() checkedFamiliesEvent: EventEmitter<{linkId: number, isLinkChecked: boolean, description: string}> =
    new EventEmitter<{linkId: number, isLinkChecked: boolean, description: string}>();

  public errorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public errorMessageSource: Observable<string> = this.errorMessageBehaviorSubject.asObservable();
  

  constructor(private lexiconService: LexiconService) {
  }

  ngOnChanges(): void {
    this.commaDelimitedIdsList = '';
  }

  public handleLink(linkId: number, isLinkChecked: boolean, description: string) {
    const linksArray: string[] = this.commaDelimitedIdsList ? (this.commaDelimitedIdsList.split(',')) : [];

    this.checkedFamiliesEvent.next({linkId: linkId, isLinkChecked: isLinkChecked, description: description});

    if (isLinkChecked) {
      linksArray.push(linkId.toString());
    } else {
      const deletedLexemeId = linksArray.findIndex(element => (element.toString() === linkId.toString()));
      linksArray.splice(deletedLexemeId, 1);
    }

    if (linksArray.length) {
      this.commaDelimitedIdsList = linksArray.join(',');
    } else {
      this.commaDelimitedIdsList = '';
    }
  }

  public deleteLinksAfterUserConfirmation(doesUserConfirmDeletion: boolean) {
    if (doesUserConfirmDeletion) {
      this.deleteLinksEvent.next(this.commaDelimitedIdsList);
    }
  }

  public replaceLinks(familyIdWhereListMovedTo: string) {
    if (familyIdWhereListMovedTo && this.commaDelimitedIdsList) {
      this.moveLinksEvent.next({
        commaDelimitedIdsList: this.commaDelimitedIdsList,
        commaDelimitedListOfTargetFamilies: familyIdWhereListMovedTo
      });
    }
  }

  public updateLinksList(commaDelimitedIdsListToAdd: string) {
    if(commaDelimitedIdsListToAdd == '-1') {
      this.refreshFamilyEvent.emit();
      return;
    }
    if (commaDelimitedIdsListToAdd) {
      this.addLinksEvent.next(commaDelimitedIdsListToAdd);
    }
  }

  public addSilentDomains(event) {
    this.addSilentDomainsEvent.emit(event);
  }

  public approveLexemeFamily(lexeme: Lexeme, familyId: number): void {
    this.isLoading = true;
    this.lexiconService.approveLexemeFamily(lexeme.id, familyId)
    .then((message: LampUpdateResponse) => {
      this.isLoading = false;
      if (message.success) {
        lexeme.unverified = 'verified';
        this.clearTheErrorMessage();
      } else {
        this.showErrorMessage(message.error);
      }
    });
  }

  public showErrorMessage(error?: string): void {
    this.errorMessageBehaviorSubject.next(error);
  }

  public clearTheErrorMessage(): void {
    this.showErrorMessage('');
  }

  getUnverifiedEmoji(unverified: 'verified' | 'failedAutoCheck' | 'autogenerated' | 'humanFactor' | 'autoGrammar' | 'autoStyle') {
    switch(unverified) {
      case 'autogenerated':
      case 'autoGrammar':
      case 'autoStyle':
        return '💡';
      case 'failedAutoCheck':
        return '❗';
      default:
        return '❓';
    }
  }
}
